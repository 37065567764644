import { GenericError, useAuth0 } from "@auth0/auth0-react";
import { authExchange } from "@urql/exchange-auth";
import { cacheExchange } from "@urql/exchange-graphcache";
import { RateLimiter } from "limiter";
import { ComponentProps, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  createClient,
  errorExchange,
  fetchExchange,
  Provider,
  subscriptionExchange,
} from "urql";

import { Events } from "../../constants/events";
import { fetchWithRetry } from "../../utils/fetch-retry";
import mixpanel from "../../utils/mixpanel";
import { GraphqlWsClientContext } from "./GraphqlWsContext";
import schema from "./introspection.json";
import resolverConfig from "./urqlCacheResolvers";
import urqlCacheUpdates from "./urqlCacheUpdates";
// fetch only rejects on network errors, not http errors
// https://github.com/apollographql/apollo-feature-requests/issues/153

const rateLimiterMinute = new RateLimiter({
  tokensPerInterval: 500,
  interval: "minute",
  fireImmediately: true,
});

const rateLimiterSecond = new RateLimiter({
  tokensPerInterval: 30,
  interval: "second",
  fireImmediately: true,
});

const getQueryOperationName = (options): string | null => {
  const body = options?.body;

  if (!body) {
    return null;
  }

  try {
    const jsonBody = JSON.parse(body);
    return jsonBody.operationName ?? null;
  } catch {
    return null;
  }
};

const customFetch: typeof fetch = async (url, options) => {
  const operation = getQueryOperationName(options);

  const remainingMinuteTokens = await rateLimiterMinute.removeTokens(1);
  const remainingSecondTokens = await rateLimiterSecond.removeTokens(1);

  if (remainingMinuteTokens < 0 || remainingSecondTokens < 0) {
    throw new Error("Rate limit exceeded");
  }

  const isSampled = Math.random() < 0.1; // Track 10% of network traffic

  try {
    if (isSampled) {
      mixpanel.safeTrack(Events.NETWORK_REQUEST, {
        operation,
      });
    }

    const response = await fetchWithRetry(url, options);

    if (response.ok) {
      return response;
    }

    const responseMessage = await response.text();

    if (isSampled) {
      mixpanel.safeTrack(Events.NETWORK_ERROR, {
        operation,
        name: "http-error",
        status: response.status,
        message: responseMessage,
      });
    }
    return Promise.reject(new Error(responseMessage));
  } catch (err) {
    if (isSampled) {
      mixpanel.safeTrack(Events.NETWORK_ERROR, {
        operation,
        name: err.name,
        status: err.status,
        message: err.message,
      });
    }

    throw err;
  }
};

const warn = console.error.bind(console); // eslint-disable-line no-console
type UrqlProviderProps = Omit<ComponentProps<typeof Provider>, "value">;

const isAuth0InvalidStateError = (error: unknown): boolean =>
  error instanceof GenericError && error.error_description === "Invalid state";

export default function UrqlProvider(props: UrqlProviderProps) {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } =
    useAuth0();

  const { client: wsClient } = useContext(GraphqlWsClientContext);

  const client = useMemo(() => {
    return createClient({
      url: new URL(
        process.env.LAYER_GRAPHQL_URI,
        window.location.origin,
      ).toString(),

      exchanges: [
        // devtoolsExchange,
        // @ts-ignore
        cacheExchange({
          schema,
          keys: {
            Link: () => null,
            RawImage: () => null,
            Error: () => null,
            UserOnboardingState: () => null,
            BillingInfo: () => null,
            StyleFinetuneSample: () => null,
            UserDismissedUI: () => null,
            UserDismissedHomepageUI: () => null,
            TranslationLanguagesResult: () => null,
            FileThumbnails: () => null,
            FileThumbnail: () => null,
          },
          resolvers: resolverConfig,
          updates: urqlCacheUpdates,
        }),
        errorExchange({
          onError({ graphQLErrors, networkError }) {
            if (graphQLErrors)
              graphQLErrors.forEach((error) =>
                warn("GraphQLError:", error.message),
              );
            if (networkError) warn("NetworkError:", networkError.message);
          },
        }),
        authExchange(async () => {
          let token: string | undefined;
          try {
            token = isAuthenticated
              ? await getAccessTokenSilently()
              : undefined;
          } catch (error) {
            warn("Failed to refresh token:", error);
          }
          return {
            addAuthToOperation(operation) {
              const fetchOptions =
                typeof operation.context.fetchOptions === "function"
                  ? operation.context.fetchOptions()
                  : operation.context.fetchOptions || {};
              return {
                ...operation,
                context: {
                  ...operation.context,
                  fetchOptions: {
                    ...fetchOptions,
                    headers: {
                      ...fetchOptions.headers,
                      authorization: `Bearer ${token}`,
                    },
                  },
                },
              };
            },
            async refreshAuth() {
              try {
                token = isAuthenticated
                  ? await getAccessTokenSilently()
                  : undefined;
                wsClient.terminate();
              } catch (error) {
                warn("Failed to refresh token:", error);
              }
            },
            didAuthError(error) {
              return error.graphQLErrors.some(
                (e) => e.extensions?.code === "FORBIDDEN",
              );
            },
          };
        }),
        fetchExchange,
        subscriptionExchange({
          forwardSubscription(request) {
            const input = { ...request, query: request.query || "" };
            return {
              subscribe(sink) {
                const unsubscribe = wsClient.subscribe(input, sink);
                return { unsubscribe };
              },
            };
          },
        }),
      ],

      fetch: customFetch,
      fetchOptions: {},

      requestPolicy: "cache-and-network", // todo: @urql/exchange-request-policy
    });
  }, [isAuthenticated, getAccessTokenSilently, wsClient]);

  if (error) {
    if (isAuth0InvalidStateError(error)) {
      navigate("/"); // this will cause auth0 to generate a new state
    } else {
      throw error; // don't worry about handling; caught by an enclosing error boundary
    }
  }
  if (isLoading) return null; // wait until Auth0 has finished loading; todo: suspense?

  return <Provider value={client} {...props} />;
}
