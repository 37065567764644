import mixpanel, { Callback, Mixpanel, RequestOptions } from "mixpanel-browser";
import { useCallback, useMemo } from "react";

import routes from "../components/app/routes";
import useBasicData from "../hooks/useBasicData";
import { parameterizePathname } from "../utils/url";

export const useMixpanel = (): Mixpanel => {
  const basicData = useBasicData();

  const enrichedTrack = useCallback(
    (
      name: string,
      properties: object,
      optionsOrCallback?: RequestOptions | Callback,
      callback?: Callback,
    ) => {
      const defaultProperties = {
        workspace: {
          id: basicData?.workspace?.id,
          name: basicData?.workspace?.displayName,
          plan: basicData?.workspace?.planTier,
        },
        path: parameterizePathname(routes, location),
      };

      const finalProperties = {
        ...properties,
        ...defaultProperties,
      };

      mixpanel.track(name, finalProperties, optionsOrCallback, callback);
    },
    [
      basicData?.workspace?.id,
      basicData?.workspace?.displayName,
      basicData?.workspace?.planTier,
    ],
  );

  const enrichedMixpanel = useMemo(() => {
    return {
      ...mixpanel,
      track: enrichedTrack,
    };
  }, [enrichedTrack]);

  return enrichedMixpanel;
};
