export const MIN_UPSCALE_FACTOR = 2;
export const MAX_UPSCALE_FACTOR = 8;
export const MAX_UPSCALE_DIMENSION = 4096;

export const FORM_FIELDS_UPSCALE_VIDEO = {
  UPSCALE_FACTOR: "upscaleFactor",
} as const;

// Create a type that includes all the values from FORM_FIELDS_UPSCALE
export type UpscaleFieldValuesType =
  (typeof FORM_FIELDS_UPSCALE_VIDEO)[keyof typeof FORM_FIELDS_UPSCALE_VIDEO];

export interface FormTypeUpscaleVideo {
  [FORM_FIELDS_UPSCALE_VIDEO.UPSCALE_FACTOR]: number;
}

export const DEFAULT_UPSCALE_VIDEO_FORM_VALUES: FormTypeUpscaleVideo = {
  [FORM_FIELDS_UPSCALE_VIDEO.UPSCALE_FACTOR]: 2,
};
