export const MAX_VECTORIZE_DIMENSION = 4096;

export const FORM_FIELDS_VECTORIZE = {} as const;

export type VectorizeFieldValuesType =
  (typeof FORM_FIELDS_VECTORIZE)[keyof typeof FORM_FIELDS_VECTORIZE];

/* eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type */
export interface FormTypeVectorize {}

export const DEFAULT_VECTORIZE_FORM_VALUES: FormTypeVectorize = {};
