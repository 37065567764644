import { Stack, styled } from "@mui/system";

export interface TooltipTitleWithImageProps {
  title: string;
  imageSrc: string;
}

const ImageContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: 0,
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.secondary[100],
  boxShadow: theme.shadows[1],
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  backgroundColor: theme.palette.secondary[900],
}));

export const TooltipTitleWithImage = ({
  title,
  imageSrc,
}: TooltipTitleWithImageProps) => {
  return (
    <Stack direction="column" spacing={1}>
      <ImageContainer>
        <Image src={imageSrc} />
      </ImageContainer>
      <span>{title}</span>
    </Stack>
  );
};
