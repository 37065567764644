import * as yup from "yup";

import { FormTypeVectorize } from "./constants";
import { getIsVectorizeTargetFileValid } from "./utils";

export const validationSchema = yup
  .object()
  .shape({})
  .test(
    "Target File Validation",
    "You must upload at least one image to vectorize.",
    function () {
      const { refinementImages } = this.options.context || {};

      if (!refinementImages?.length) return false;
      return refinementImages.every(
        (refinementImage) =>
          refinementImage?.width &&
          refinementImage?.height &&
          getIsVectorizeTargetFileValid(refinementImage),
      );
    },
  ) as yup.ObjectSchema<FormTypeVectorize>;
