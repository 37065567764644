import { createSvgIcon, SvgIconProps } from "@mui/material";

export const VectorizeIcon = (props: SvgIconProps) => {
  const SvgIcon = createSvgIcon(
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.42769 5.77478H4.42355C3.4938 5.77478 2.75 6.55577 2.75 7.48552C2.75 8.11775 3.08471 8.7128 3.67975 9.01032L4.83264 9.60536C5.01859 9.67974 5.13017 9.86569 5.13017 10.0516C5.13017 10.312 4.86983 10.5351 4.6095 10.5351H3.34504C3.01033 10.5351 2.75 10.8326 2.75 11.1302C2.75 11.4649 3.01033 11.7252 3.34504 11.7252H4.6095C5.53926 11.7252 6.32025 10.9814 6.32025 10.0516C6.32025 9.38222 5.94835 8.82437 5.35331 8.52685L4.20041 7.9318C4.01446 7.85742 3.94008 7.67147 3.94008 7.48552C3.94008 7.22519 4.16322 6.96486 4.42355 6.96486H5.42769C5.72521 6.96486 6.02273 6.70453 6.02273 6.36982C6.02273 6.0723 5.72521 5.77478 5.42769 5.77478Z"
        fill="currentColor"
      />
      <path
        d="M13.2624 5.77478H12.6674C11.812 5.77478 11.1798 6.4442 11.1798 7.26238V10.2376C11.1798 11.093 11.812 11.7252 12.6674 11.7252H13.2624C14.0806 11.7252 14.75 11.093 14.75 10.2376V9.34503C14.75 9.04751 14.4525 8.74999 14.155 8.74999H13.5599C13.2252 8.74999 12.9649 9.04751 12.9649 9.34503C12.9649 9.67974 13.2252 9.94007 13.5599 9.94007V10.2376C13.5599 10.4235 13.4112 10.5351 13.2624 10.5351H12.6674C12.4814 10.5351 12.3698 10.4235 12.3698 10.2376V7.26238C12.3698 7.11362 12.4814 6.96486 12.6674 6.96486H13.2624C13.4112 6.96486 13.5599 7.11362 13.5599 7.26238V7.5599C13.5599 7.89462 13.8202 8.15495 14.155 8.15495C14.4525 8.15495 14.75 7.89462 14.75 7.5599V7.26238C14.75 6.4442 14.0806 5.77478 13.2624 5.77478Z"
        fill="currentColor"
      />
      <path
        d="M8.15496 6.36982C8.15496 6.0723 7.85744 5.77478 7.55992 5.77478C7.22521 5.77478 6.96488 6.0723 6.96488 6.36982V7.5599C6.96488 8.86156 7.33678 10.126 8.04339 11.2045L8.22934 11.4649C8.34091 11.6508 8.52686 11.7252 8.75 11.7252C8.93595 11.7252 9.1219 11.6508 9.23347 11.4649L9.41942 11.2045C10.126 10.126 10.5351 8.86156 10.5351 7.5599V6.36982C10.5351 6.0723 10.2376 5.77478 9.94008 5.77478C9.60537 5.77478 9.34504 6.0723 9.34504 6.36982V7.5599C9.34504 8.41528 9.1219 9.27065 8.75 10.0144C8.34091 9.27065 8.15496 8.41528 8.15496 7.5599V6.36982Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4375 0C1.52344 0 0 1.5625 0 3.4375V14.0625C0 15.9766 1.52344 17.5 3.4375 17.5H14.0625C15.9375 17.5 17.5 15.9766 17.5 14.0625V3.4375C17.5 1.5625 15.9375 0 14.0625 0H3.4375ZM3.4375 1.25C2.22656 1.25 1.25 2.26562 1.25 3.4375V14.0625C1.25 15.2734 2.22656 16.25 3.4375 16.25H14.0625C15.2344 16.25 16.25 15.2734 16.25 14.0625V3.4375C16.25 2.26562 15.2344 1.25 14.0625 1.25H3.4375Z"
        fill="currentColor"
      />
    </svg>,
    "VectorizeIcon",
  );

  return <SvgIcon style={{ fontSize: 16, display: "block" }} {...props} />;
};
