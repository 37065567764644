import { Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";

import { Button } from "../ui-v2";

const CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds

const NewVersionMessage = () => {
  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <span>New version of Layer is available!</span>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => window.location.assign(window.location.href)}
      >
        Reload
      </Button>
    </Stack>
  );
};

export const VersionChecker = () => {
  const { enqueueSnackbar } = useSnackbar();
  const versionHash = useRef<null | string>(null);
  const lastCheckTime = useRef<number>(0);

  const getVersion = async () => {
    try {
      const response = await fetch("/version");
      const newVersionHash = await response.text();
      const isValidVersion = /^\d+$/.test(newVersionHash);
      const previousVersionSet = !!versionHash.current;
      const versionHashChanged = versionHash.current !== newVersionHash;

      if (isValidVersion) {
        if (previousVersionSet && versionHashChanged) {
          enqueueSnackbar(<NewVersionMessage />, {
            variant: "info",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }

        versionHash.current = newVersionHash;
      }

      // Update the last check timestamp
      lastCheckTime.current = Date.now();
    } catch (error) {
      console.error("Failed to fetch version:", error);
    }
  };

  const pollGetVersion = () => {
    const currentTime = Date.now();

    // Check if enough time has passed since the last check
    if (currentTime - lastCheckTime.current < CHECK_INTERVAL) {
      return;
    }

    // Document does not have focus
    if (!document.hasFocus()) {
      return;
    }

    getVersion();
  };

  useEffect(() => {
    let intervalId = null;

    // Set an interval to check the version every check interval
    intervalId = setInterval(pollGetVersion, CHECK_INTERVAL);

    // Add event listener for window focus
    window.addEventListener("focus", getVersion);

    // Run the version check when the component mounts
    getVersion();

    return () => {
      // Cleanup on unmount: remove event listener and clear interval
      window.removeEventListener("focus", getVersion);
      clearInterval(intervalId);
    };
  }, []);

  return null;
};
