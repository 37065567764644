import MDEditor from "@uiw/react-md-editor";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useMutation } from "urql";

import { DismissWorkspaceNotices } from "../../features/workspaces/queries.graphql";

type TeamNoticesProps = {
  workspaceId: string;
  workspaceName: string;
  workspaceLogo: string;
  notices: string[];
};

export default function TeamNotices(props: TeamNoticesProps) {
  const { workspaceId, workspaceName, workspaceLogo, notices } = props;

  const [dismissWorkspaceNoticesResult, dismissWorkspaceNotices] = useMutation(
    DismissWorkspaceNotices,
  );

  const onAccept = () => {
    dismissWorkspaceNotices({
      dismissWorkspaceNoticesInput: { workspaceId },
    });
  };

  return (
    <div>
      <Helmet>
        <title>{workspaceName} | Team Notices</title>
      </Helmet>
      <div className="bg-white dark:bg-layer-950">
        <div className="relative z-[1] mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl flex flex-col items-center">
            <div className="flex justify-center">
              <div className="flex justify-center items-center w-14 h-14 rounded-full bg-gray-800">
                <img
                  src={workspaceLogo}
                  alt="Logo"
                  className="w-10 h-10 object-cover rounded-full"
                />
              </div>
            </div>
            <h2 className="mt-6 font-extrabold text-center text-2xl tracking-tight text-layer-200 sm:text-2xl">
              Please accept your team&apos;s notices
            </h2>
            {notices.map((notice, idx) => (
              <div
                key={`team-notice-${idx}`}
                className="w-full mt-6 border rounded border-gray-800 p-4 text-gray-400"
              >
                <MDEditor.Markdown
                  source={notice}
                  style={{
                    whiteSpace: "pre-wrap",
                    background: "transparent",
                    fontFamily: "inherit",
                    color: "inherit",
                  }}
                />
              </div>
            ))}

            <button
              className={classNames(
                "mx-auto mt-6 w-72 rounded-md px-4 py-2 text-sm font-semibold text-white",
                { "bg-purple-600": !dismissWorkspaceNoticesResult.fetching },
                { "bg-layer-600": dismissWorkspaceNoticesResult.fetching },
              )}
              onClick={onAccept}
              disabled={dismissWorkspaceNoticesResult.fetching}
            >
              Accept and Jump In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
