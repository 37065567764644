import { Box } from "@mui/system";

import { AspectRatio } from "../../../../types";
import { InferenceStyleFragment } from "../../../inference/queries.graphql";
import { FormControlLabel, Radio, Typography } from "../../../ui-v2";
import { getDimensionsString } from "../../utils/utils";
import { ReferenceType } from "../References/types";
import {
  getAspectRatioPresetsFromReferences,
  getReferencesWithUnsupportedAspectRatio,
} from "../References/utils";
import { PresetIcon } from "./components/PresetIcon/PresetIcon";
import { ASPECT_RATIO_MAP, SORTED_ASPECT_RATIO_TYPES } from "./constants";
import { DimensionsType, PresetType } from "./types";

export const getClosestPresetToCurrentDimensions = (
  presets: PresetType[],
  currentDimensions: DimensionsType,
) => {
  const sorter = presetSorterByDistanceToCurrentDimensions(currentDimensions);
  return [...presets].sort(sorter)[0];
};

export const presetSorterByDistanceToCurrentDimensions = (
  d: DimensionsType,
) => {
  return (a: PresetType, b: PresetType) => {
    const distanceA = aspectRatioDistanceBetweenDimensions(a.dimensions, d);
    const distanceB = aspectRatioDistanceBetweenDimensions(b.dimensions, d);
    const diff = distanceA - distanceB;
    if (diff < 0.01) {
      return (
        distanceBetweenDimensions(a.dimensions, d) -
        distanceBetweenDimensions(b.dimensions, d)
      );
    }
    return diff;
  };
};

export const aspectRatioDistanceBetweenDimensions = (
  a: DimensionsType,
  b: DimensionsType,
) => {
  const aspectRatioA = a[0] / a[1];
  const aspectRatioB = b[0] / b[1];
  return Math.abs(Math.log(aspectRatioA / aspectRatioB));
};

export const distanceBetweenDimensions = (
  a: DimensionsType,
  b: DimensionsType,
) => {
  return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
};

export const getPresetFromAspectRatio = (
  aspectRatio: AspectRatio,
): PresetType => ({
  ...ASPECT_RATIO_MAP[aspectRatio.type],
  dimensions: [aspectRatio.width, aspectRatio.height],
});

export const getPresetsFromAspectRatios = (
  aspectRatios: AspectRatio[],
): PresetType[] =>
  [...aspectRatios]
    .sort(
      (a, b) =>
        SORTED_ASPECT_RATIO_TYPES.indexOf(a.type) -
        SORTED_ASPECT_RATIO_TYPES.indexOf(b.type),
    )
    .map(getPresetFromAspectRatio);

export const getPresetsFromStyleAndReferences = (
  style: InferenceStyleFragment,
  references: ReferenceType[],
) => {
  let stylePresets: PresetType[] = [],
    referencesPresets: PresetType[] = [];
  if (style?.capabilities) {
    let aspectRatios = style.capabilities.textToOutputAspectRatios;
    if (references?.length && style.capabilities.imageToOutputAspectRatios) {
      aspectRatios = style.capabilities.imageToOutputAspectRatios;
    }
    stylePresets = style.capabilities
      ? getPresetsFromAspectRatios(aspectRatios)
      : [];
    if (style.capabilities.customSizeStep && references) {
      const referencesWithAspectRatioNotInStyle =
        getReferencesWithUnsupportedAspectRatio(references, stylePresets);
      referencesPresets = style?.capabilities
        ? getAspectRatioPresetsFromReferences(
            referencesWithAspectRatioNotInStyle,
            style,
          )
        : [];
    }
  }
  return [...stylePresets, ...referencesPresets];
};

export const findPresetByDimensions = (
  presets: PresetType[],
  dimensions: DimensionsType,
) => presets.find((p) => getAreDimensionsEqual(p.dimensions, dimensions));

export const getAreDimensionsEqual = (
  dimensions1: DimensionsType,
  dimensions2: DimensionsType,
) => dimensions1[0] === dimensions2[0] && dimensions1[1] === dimensions2[1];

export const getAreAspectRatiosEqual = (
  dimensions1: DimensionsType,
  dimensions2: DimensionsType,
) => dimensions1[0] / dimensions1[1] === dimensions2[0] / dimensions2[1];

export const getAspectRatioRadioGroupItems = (
  value: DimensionsType,
  onChange: (dimensions: DimensionsType) => void,
  presets: PresetType[],
) => {
  const radioGroupItems = [];
  presets.forEach((preset, ix) => {
    const selected = getAreDimensionsEqual(value, preset.dimensions);
    if (ix === 0 || presets[ix - 1].label !== preset.label) {
      radioGroupItems.push(
        <Box key={`header-${preset.label}`}>
          <Typography variant="small" color="secondary.300">
            {preset.label}
          </Typography>
        </Box>,
      );
    }
    radioGroupItems.push(
      <FormControlLabel
        key={preset.dimensions.join(",")}
        sx={{
          "& .MuiFormControlLabel-label": {
            ...(selected && {
              color: "primary.400",
            }),
          },
        }}
        value={preset.dimensions.join(",")}
        control={
          <Radio
            onChange={() => onChange(preset.dimensions)}
            icon={<PresetIcon preset={preset} />}
            checkedIcon={<PresetIcon preset={preset} />}
          />
        }
        label={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="small">
              {preset.aspectRatioLabel || "Custom"}
            </Typography>
            <Typography color="secondary.300" variant="small">
              {getDimensionsString(preset.dimensions)}
            </Typography>
          </Box>
        }
        slotProps={{
          typography: {
            flex: 1,
          },
        }}
      />,
    );
  });
  return radioGroupItems;
};
