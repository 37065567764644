import { useEffect, useMemo } from "react";
import { create } from "zustand";

interface AffectedComponentResponse {
  id: string;
  name: string;
  group_name?: string;
}

interface MaintenanceResponse {
  id: string;
  name: string;
  status: string;
  url: string;
  last_update_at: string;
  last_update_message: string;
  started_at: string;
  scheduled_end_at: string;
  starts_at: string;
  ends_at: string;
  affected_components: AffectedComponentResponse[];
}

type IncidentStatus = "identified" | "investigating" | "monitoring";

interface IncidentResponse {
  id: string;
  name: string;
  status: IncidentStatus;
  url: string;
  last_update_at: string;
  last_update_message: string;
  current_worst_impact: string;
  affected_components: AffectedComponentResponse[];
}

interface SummaryResponse {
  page_title: string;
  page_url: string;
  ongoing_incidents: IncidentResponse[];
  in_progress_maintenances: MaintenanceResponse[];
  scheduled_maintenances: MaintenanceResponse[];
}

interface IncidentSummaryState {
  summary: SummaryResponse | null;
  error: Error | null;
  isLoading: boolean;
  fetchSummary: () => Promise<void>;
}

const URL = "https://status.layer.ai/api/v1/summary";

// This store prevents unnecessary data refetching, especially in cases like opening and closing the menu, which triggers a re-render.
const useIncidentSummaryStore = create<IncidentSummaryState>((set) => ({
  summary: null,
  error: null,
  isLoading: true,
  fetchSummary: async () => {
    set({ isLoading: true });
    try {
      const response = await fetch(URL);
      const data = await response.json();
      set({ summary: data, isLoading: false });
    } catch (e) {
      console.error("Failed to fetch incident summary", e);
      set({ isLoading: false, error: e });
    }
  },
}));

const useIncidentSummary = () => {
  const { summary, isLoading, fetchSummary } = useIncidentSummaryStore();

  useEffect(() => {
    if (!summary) {
      fetchSummary();
    }
  }, [summary, fetchSummary]);

  return { summary, isLoading };
};

const DEFAULT_STATUSES: IncidentStatus[] = ["identified"];

export const useIncidents = (statuses = DEFAULT_STATUSES) => {
  const { summary, isLoading } = useIncidentSummary();

  const incidents = useMemo(() => {
    return summary?.ongoing_incidents.filter((incident) =>
      statuses.includes(incident.status),
    );
  }, [summary, statuses]);

  return { incidents, isLoading };
};
