import * as yup from "yup";

import { StyleCapabilities } from "../../../../types";
import { validationSchemaWithStyle } from "../../fields/Style/utils";
import { FORM_FIELDS_VIDEO, FormTypeVideo } from "./constants";

export const validationSchema = yup.object().shape({
  ...validationSchemaWithStyle,
  [FORM_FIELDS_VIDEO.TARGET_FILE]: yup
    .object()
    .nullable()
    .when(
      [FORM_FIELDS_VIDEO.STYLE_CAPABILITIES],
      ([styleCapabilities]: [StyleCapabilities], schema) => {
        if (!styleCapabilities) {
          return schema;
        }

        if (
          !styleCapabilities.textPrompt &&
          styleCapabilities.imageTypes.includes("INIT")
        ) {
          return schema.test(
            "video-image-only",
            "The selected style requires an image.",
            (value: any) => {
              return !!value;
            },
          );
        }
      },
    ),
  [FORM_FIELDS_VIDEO.PROMPT_TEXT]: yup
    .string()
    .when(
      [FORM_FIELDS_VIDEO.STYLE_CAPABILITIES],
      ([styleCapabilities]: [StyleCapabilities], schema) => {
        if (!styleCapabilities) {
          return schema;
        }

        if (
          styleCapabilities.textPrompt &&
          !styleCapabilities.imageTypes.includes("INIT")
        ) {
          return schema.required("Prompt text is required.");
        }
      },
    ),
  textOrImage: yup
    .mixed()
    .test("textOrImage", "Either text or image is required", function () {
      const {
        [FORM_FIELDS_VIDEO.PROMPT_TEXT]: text,
        [FORM_FIELDS_VIDEO.TARGET_FILE]: image,
      } = this.parent;

      return !!text?.trim() || !!image;
    }),

  // Other fields not implemented yet
}) as yup.ObjectSchema<FormTypeVideo>;
