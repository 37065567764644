import { AspectRatioType } from "../../../../types";
import { PresetType } from "./types";

const getPresetAspectRatioFields = (
  h: number,
  w: number,
): Pick<PresetType, "aspectRatio" | "aspectRatioLabel"> => ({
  aspectRatioLabel: `${h}:${w}`,
  aspectRatio: h / w,
});

export const ASPECT_RATIO_MAP: Record<
  AspectRatioType,
  Omit<PresetType, "dimensions">
> = {
  SQUARE: { label: "Square", ...getPresetAspectRatioFields(1, 1) },
  SQUARE_HD: { label: "Square HD", ...getPresetAspectRatioFields(1, 1) },
  LANDSCAPE_3_2: { label: "Landscape", ...getPresetAspectRatioFields(3, 2) },
  LANDSCAPE_4_3: { label: "Landscape", ...getPresetAspectRatioFields(4, 3) },
  LANDSCAPE_16_9: { label: "Landscape", ...getPresetAspectRatioFields(16, 9) },
  PORTRAIT_2_3: { label: "Portrait", ...getPresetAspectRatioFields(2, 3) },
  PORTRAIT_3_4: { label: "Portrait", ...getPresetAspectRatioFields(3, 4) },
  PORTRAIT_9_16: { label: "Portrait", ...getPresetAspectRatioFields(9, 16) },
};

export const SORTED_ASPECT_RATIO_TYPES = Object.keys(
  ASPECT_RATIO_MAP,
) as AspectRatioType[];
