import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { icon as faIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Box,
  ListItemIconProps,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  MenuProps,
  Stack,
  styled,
  Theme,
} from "@mui/material";
import { forwardRef, useState } from "react";

import { Icon, ListItemIcon, ListItemText } from "../..";

declare module "@mui/material/MenuItem" {
  interface MenuItemOwnProps {
    variant?: "primary" | "secondary" | "tertiary";
    icon?: React.ReactElement;
    showSelectedIcon?: boolean;
    endLabel?: string;
    endIcon?: React.ReactElement;
    hasSubMenu?: boolean;
    ListItemIconProps?: ListItemIconProps;
    loading?: boolean;
    renderSubMenu?: (menuProps: MenuProps) => React.ReactElement;
  }
}

const StyledSelectedIcon = styled(Icon)(
  ({ theme }: { theme: Theme; icon: IconDefinition }) => ({
    color: theme.palette.secondary[50],
    fontSize: theme.typography.small.fontSize,
  }),
);

const MenuItemNoForward = <C extends React.ElementType>(
  props: MenuItemProps<C, { component?: C }>,
  ref: React.Ref<HTMLLIElement>,
) => {
  const {
    variant = "secondary",
    icon,
    endLabel,
    endIcon,
    disabled = false,
    loading = false,
    selected = false,
    showSelectedIcon = false,
    hasSubMenu = false,
    renderSubMenu,
    ListItemIconProps = {},
    children,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenSubmenu = (e) => {
    if (hasSubMenu) {
      setAnchorEl(e.currentTarget);
    }

    rest.onClick?.(e);
  };

  const handleCloseSubmenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MuiMenuItem
        variant={variant}
        selected={selected}
        disabled={disabled || loading}
        onClick={handleOpenSubmenu}
        {...rest}
        ref={ref}
      >
        {loading ? (
          <ListItemIcon>
            <Icon
              icon={faIcon({ name: "spinner-third" })}
              className="animate-spin"
            />
          </ListItemIcon>
        ) : (
          icon && <ListItemIcon {...ListItemIconProps}>{icon}</ListItemIcon>
        )}
        <Stack spacing={6} direction="row" alignItems="center" flex={1}>
          <ListItemText primary={children} />
          <Stack spacing={2} direction="row" alignItems="center">
            <ListItemText secondary={endLabel} />
            {endIcon}
            {hasSubMenu && (
              <Icon
                size="small"
                icon={faIcon({
                  name: "caret-right",
                  family: "sharp",
                  style: "solid",
                })}
              />
            )}
            {showSelectedIcon && (
              <Box sx={{ opacity: selected ? 1 : 0 }}>
                <StyledSelectedIcon
                  icon={faIcon({
                    name: "check",
                    family: "sharp",
                    style: "solid",
                  })}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </MuiMenuItem>
      {renderSubMenu &&
        renderSubMenu({
          open: !!anchorEl,
          anchorEl,
          anchorOrigin: { vertical: "top", horizontal: "right" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          onClose: handleCloseSubmenu,
        })}
    </>
  );
};

export const MenuItem = forwardRef(MenuItemNoForward);
