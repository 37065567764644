import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { ReactNode } from "react";

import { getAssetURL } from "../../utils/assets";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    enterDelay={1000}
    enterNextDelay={1000}
  />
))(() => ({
  [`&& .${tooltipClasses.tooltip}`]: {
    borderRadius: "0.5rem",
    overflow: "hidden",
    backgroundColor: "transparent",
    backdropFilter: "blur(16px)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 240,
    border: "0px",
    padding: "0px",
    margin: "4px",
  },
}));

type AdvancedTooltipButtonProps = {
  key: string;
  label: string | JSX.Element;
  onClick?: () => void;
};

export type AdvancedTooltipProps = Omit<TooltipProps, "title"> & {
  name: string;
  description: string;
  icon?: JSX.Element;
  videoUrl?: string;
  buttons?: AdvancedTooltipButtonProps[];
  footer?: ReactNode;
  onClose?: () => void;
};

export default function AdvancedTooltip({
  name,
  description,
  icon,
  videoUrl,
  buttons,
  footer,
  onClose,
  children,
  ...otherProps
}: AdvancedTooltipProps) {
  return (
    <StyledTooltip
      title={
        <div className="w-full border bg-layer-50 dark:bg-layer-900 border-layer-800 rounded-lg overflow-clip">
          <div className="relative w-[240px]">
            {videoUrl && (
              <>
                <img
                  width="100%"
                  className="object-cover block bg-transparent"
                  src={getAssetURL("style_placeholder_blank.png")}
                />

                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height="100%"
                  className="absolute z-10 inset-0 w-full h-full"
                />

                <picture className="absolute z-20 inset-0 w-full h-full">
                  <video
                    width="240"
                    muted
                    autoPlay
                    loop
                    className="object-cover"
                  >
                    <source src={videoUrl} type="video/mp4"></source>
                  </video>
                </picture>
              </>
            )}
          </div>
          <div className="p-2">
            <div className="flex flex-row space-x-1 items-center mb-1">
              <h4 className="text-base text-layer-700 dark:text-layer-200 grow">
                {name}
              </h4>

              {icon && (
                <div className="w-8 h-8 flex items-center justify-center rounded-lg m-0 mr-0.5 group select-none">
                  <div className="w-5 h-5 flex items-center justify-center rounded m-0 text-sm font-bold text-layer-300 bg-layer-200 dark:bg-layer-700">
                    {icon}
                  </div>
                </div>
              )}
              {onClose && (
                <button
                  className="h-8 px-1 flex items-center justify-center rounded-full group select-none text-layer-500"
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={light("xmark")} className="text-xl" />
                </button>
              )}
            </div>

            <p className="text-sm text-layer-300 mb-4">{description}</p>

            {buttons && (
              <div className="w-full">
                <div className="flex justify-end">
                  {buttons.map((button) => (
                    <button
                      key={button.key}
                      className="h-8 px-2 pb-px flex items-center justify-center font-medium text-sm transition-all rounded-lg cursor-pointer bg-transparent shadow-[0px_0px_0px_1px_inset_rgba(0,0,0,0.1)] text-layer-500 hover:bg-layer-900/5"
                      onClick={button.onClick}
                    >
                      {button.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {footer}
          </div>
        </div>
      }
      {...otherProps}
    >
      {children}
    </StyledTooltip>
  );
}
