import { useMutation } from "urql";

import {
  CreateStyleInput,
  DeleteStyleInput,
  Modality,
  SessionInferenceMode,
  UpdateStyleInput,
} from "../../components/graphql/schema.graphql";
import { InferenceFormNavigationState } from "../../components/inference-forms/constants";
import { FORM_FIELDS_2D } from "../../components/inference-forms/forms/2D/constants";
import { FORM_FIELDS_3D } from "../../components/inference-forms/forms/3D/constants";
import { FORM_FIELDS_VIDEO } from "../../components/inference-forms/forms/Video/constants";
import { useNavigate } from "../";
import { useUrqlResponseHandler } from "../useUrqlResponseHandler";
import { CreateStyle2, DeleteStyle2, UpdateStyle2 } from "./queries.graphql";

export const useStyleActions = () => {
  const navigate = useNavigate();
  const [createMutation, executeCreation] = useMutation(CreateStyle2);
  const [updateMutation, executeUpdate] = useMutation(UpdateStyle2);
  const [deleteMutation, executeDelete] = useMutation(DeleteStyle2);

  const { getResponseFromState } = useUrqlResponseHandler();

  const createStyle = async (
    input: CreateStyleInput,
    successMessage?: string,
  ) => {
    const state = await executeCreation({
      createStyleInput: input,
    });
    const { data, error } = getResponseFromState({
      state,
      successMessage: successMessage || "Style created",
      errorMessage: "Unfortunately we could not create your style",
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const updateStyle = async (
    input: UpdateStyleInput,
    successMessage?: string,
  ) => {
    const state = await executeUpdate({
      updateStyleInput: input,
    });
    const { data, error } = getResponseFromState({
      state,
      successMessage: successMessage || "Style updated",
      errorMessage: "Unfortunately we could not update your style",
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const deleteStyle = async (
    input: DeleteStyleInput,
    successMessage?: string,
  ) => {
    const state = await executeDelete({
      deleteStyleInput: input,
    });
    const { data, error } = getResponseFromState({
      state,
      successMessage: successMessage || "Style deleted",
      errorMessage: "Unfortunately we could not delete your style",
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const navigateToForgeWithStyle = (
    workspaceName: string,
    modality: Modality,
    styleId: string,
  ) => {
    const style = { id: styleId, weight: 100 };
    let navigationState: InferenceFormNavigationState;
    switch (modality) {
      case "IMAGE":
        navigationState = {
          form: {
            [FORM_FIELDS_2D.STYLE]: style,
          },
          mode: "CREATE" as SessionInferenceMode,
        };
        break;
      case "THREE_D":
        navigationState = {
          form: {
            [FORM_FIELDS_3D.STYLE]: style,
          },
          mode: "CREATE_3D" as SessionInferenceMode,
        };
        break;
      case "VIDEO":
        navigationState = {
          form: {
            [FORM_FIELDS_VIDEO.STYLE]: style,
          },
          mode: "CREATE_VIDEO" as SessionInferenceMode,
        };
    }
    navigate<InferenceFormNavigationState>(`/${workspaceName}/session/new`, {
      state: navigationState,
    });
  };

  return {
    createStyle: {
      execute: createStyle,
      state: createMutation,
    },
    updateStyle: {
      execute: updateStyle,
      state: updateMutation,
    },
    deleteStyle: {
      execute: deleteStyle,
      state: deleteMutation,
    },
    navigateToForgeWithStyle,
  };
};
