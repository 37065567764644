import {
  FormControl as MuiFormControl,
  FormControlProps,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";

import { Chip } from "..";
import { ChevronIcon } from "../icons";

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    medium: true;
    large: true;
  }
}

declare module "@mui/material/FormControl" {
  interface FormControlPropsSizeOverrides {
    medium: true;
    large: true;
  }
}

declare module "@mui/material/Select" {
  interface InputBasePropsSizeOverrides {
    medium: true;
    large: true;
  }
}

declare module "@mui/material/InputLabel" {
  interface InputLabelPropsSizeOverrides {
    medium: true;
    large: true;
  }
}

type SelectProps = MuiSelectProps & {
  placeholder?: string;
  focused?: boolean;
};

const getCommonFormControlStyles = (theme, variant, disabled) => {
  const { palette, typography } = theme;
  return {
    color: palette.secondary[300],
    fontSize: typography.small.fontSize,
    background: "transparent",
    opacity: disabled ? 0.5 : 1,
  };
};

const variantStyles = (theme, variant, error) => {
  const { palette } = theme;
  if (variant === "filled") {
    return {
      background: palette.secondary["800/75"],
      border: `1px solid ${error ? palette.error[900] : palette.secondary[700]}`,
      "&:hover": {
        background: palette.secondary["800/75"],
        border: `1px solid ${error ? palette.error[500] : palette.secondary[500]}`,
      },
      "& .MuiFilledInput-input:focus": {
        background: "transparent",
      },
    };
  }
  if (variant === "outlined") {
    return {
      border: `1px solid ${palette.secondary[700]}`,
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.secondary[500],
      },
      "& legend": {
        display: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        top: -1,
        left: -1,
        bottom: -1,
        right: -1,
        border: `1px solid ${palette.secondary[700]}`,
      },
    };
  }
  if (variant === "standard") {
    return {
      paddingLeft: 0,

      "&&": {
        marginTop: 0,
      },
    };
  }

  return {};
};

const sizeStyles = (theme, size, multiple) => {
  const { spacing } = theme;
  const sizeConfig = {
    large: {
      minHeight: spacing(9),
      borderRadius: 20,
      marginLeft: -1,
      "& .MuiSelect-select": {
        height: "100%",
        paddingTop: multiple ? spacing(0.75) : 0,
        paddingBottom: multiple ? spacing(0.75) : 0,
        paddingLeft: multiple ? spacing(1) : spacing(3),
        paddingRight: spacing(3),

        "&&&": {
          height: "100%",
        },
      },
    },
    medium: {
      minHeight: spacing(8),
      borderRadius: 4,
      "& .MuiSelect-select": {
        paddingTop: multiple ? spacing(0.75) : 0,
        paddingBottom: multiple ? spacing(0.75) : 0,
        paddingLeft: multiple ? spacing(0.75) : spacing(2),
        paddingRight: spacing(2.5),

        "&&&&&&": {
          height: "100%",
        },
      },
    },
    small: {
      minHeight: spacing(7),
      borderRadius: 4,
      "& .MuiSelect-select": {
        paddingTop: multiple ? spacing(0.75) : 0,
        paddingBottom: multiple ? spacing(0.75) : 0,
        paddingLeft: multiple ? spacing(0.75) : spacing(2),
        paddingRight: spacing(2),

        "&&&&&&": {
          height: "100%",
        },
      },
    },
  };

  return sizeConfig[size] || sizeConfig.small;
};

const selectStyles = (theme, size, multiple) => {
  const { spacing } = theme;
  const sizeConfig = {
    large: {
      minHeight: spacing(9),
      paddingTop: multiple ? spacing(0.75) : 0,
      paddingBottom: multiple ? spacing(0.75) : 0,
      paddingLeft: multiple ? spacing(1) : spacing(3),
      paddingRight: spacing(3),
    },
    medium: {
      minHeight: spacing(8),
      paddingTop: multiple ? spacing(0.75) : 0,
      paddingBottom: multiple ? spacing(0.75) : 0,
      paddingLeft: multiple ? spacing(0.75) : spacing(2),
      paddingRight: spacing(2.5),
      marginBottom: multiple ? 0 : -1,
    },
    small: {
      minHeight: spacing(7),
      paddingTop: multiple ? spacing(0.75) : 0,
      paddingBottom: multiple ? spacing(0.75) : 0,
      paddingLeft: multiple ? spacing(0.75) : spacing(2),
      paddingRight: spacing(2),
    },
  };

  return sizeConfig[size] || sizeConfig.small;
};

const getFormControlStyles = (theme, variant, disabled) => ({
  ...getCommonFormControlStyles(theme, variant, disabled),
  ...(variant === "filled" && {
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: theme.palette.secondary[200],
    },
    "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
      background: theme.palette.secondary["800/75"],
      border: `1px solid ${theme.palette.secondary[500]}`,
    },
    "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
      background: theme.palette.secondary["800/75"],
      border: `1px solid ${theme.palette.secondary[700]}`,
    },
  }),
});

const getSelectStyles = (theme, size, multiple, variant, error) => ({
  ...sizeStyles(theme, size, multiple),
  ...variantStyles(theme, variant, error),
  "& .multiple-placeholder": {
    paddingLeft: 6,
    display: multiple ? "inline-block" : "unset",
  },
  "& .MuiSelect-icon": {
    color: theme.palette.secondary[300],
    top: {
      large: "10px",
      medium: "8px",
      small: "6px",
    }[size],
    right: {
      large: "10px",
      medium: "7px",
      small: "6px",
    }[size],
  },
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    ...selectStyles(theme, size, multiple),
  },
  "&& .MuiSelect-select": {
    height: "100%",
  },
});

const StyledFormControl = styled(MuiFormControl)<FormControlProps>(
  ({ theme, variant, disabled }) =>
    getFormControlStyles(theme, variant, disabled),
);

const StyledInputLabel = styled(MuiInputLabel)<SelectProps>(({
  theme,
  variant,
}) => {
  const { palette } = theme;
  return {
    fontSize: "12px",
    color:
      variant === "standard" ? palette.secondary[300] : palette.secondary[200],
    position: "relative",
    transform: "none",
    fontWeight: 600,
    marginBottom: 6,
    paddingLeft: 0,
  };
});

const StyledSelect = styled(MuiSelect)<SelectProps>(
  ({ theme, size, multiple, variant, error }) =>
    getSelectStyles(theme, size, multiple, variant, error),
);

const SelectPlaceholder = styled("span")(({ theme }) => ({
  color: theme.palette.secondary[300],
}));

export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      size = "small",
      variant = "filled",
      placeholder = "Select an option",
      multiple = false,
      label,
      error,
      focused,
      disabled,
      required,
      inputProps,
      fullWidth,
      ...rest
    },
    ref,
  ) => {
    const renderValue = (selected: string | string[]) => {
      return multiple
        ? renderMultipleValues(selected as string[])
        : renderSingleValue(selected as string);
    };

    const renderSingleValue = (selected: string) => {
      if (selected) {
        return <>{selected}</>;
      }

      return <SelectPlaceholder>{placeholder}</SelectPlaceholder>;
    };

    const renderMultipleValues = (selected: string[]) => {
      if (selected.length > 0) {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size={size} />
            ))}
          </Box>
        );
      }

      return (
        <SelectPlaceholder className="multiple-placeholder">
          {placeholder}
        </SelectPlaceholder>
      );
    };

    return (
      <StyledFormControl
        variant={variant}
        size={size}
        ref={ref}
        error={error}
        focused={focused}
        disabled={disabled}
        required={required}
        fullWidth={fullWidth}
      >
        {label && (
          <StyledInputLabel
            size={size}
            variant={variant}
            disableAnimation
            shrink={false}
          >
            {label}
          </StyledInputLabel>
        )}

        <StyledSelect
          variant={variant}
          size={size}
          IconComponent={ChevronIcon}
          displayEmpty
          multiple={multiple}
          error={error}
          disabled={disabled}
          inputProps={inputProps}
          renderValue={renderValue}
          fullWidth={fullWidth}
          {...rest}
        />
      </StyledFormControl>
    );
  },
);
