import { SessionInferenceMode } from "../../../graphql/schema.graphql";

export const REFINEMENT_MODES: SessionInferenceMode[] = [
  "UPSCALE",
  "VECTORIZE_IMAGE",
  "REMOVE_BACKGROUND",
  "UPSCALE_VIDEO",
];

export type RefinementFormMode = (typeof REFINEMENT_MODES)[number];
