import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

export default function WithAuthenticationRequired<P extends object>(props: P) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const { connection, screen_hint, login_hint } =
    Object.fromEntries(searchParams);

  const returnTo = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          connection,
          screen_hint,
          login_hint,
        },
        appState: { returnTo },
      });
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    connection,
    screen_hint,
    login_hint,
    returnTo,
  ]);

  if (isLoading || !isAuthenticated) {
    return null;
  }

  return <Outlet {...props} />;
}
