import { UpdatesConfig } from "@urql/exchange-graphcache";

import {
  MutationDeleteDriveItemsArgs,
  MutationDeleteStyleArgs,
  MutationUpdateStyleArgs,
} from "./schema.graphql";

const updatesConfig: Partial<UpdatesConfig> = {
  Mutation: {
    deleteStyle: (result, { input }: MutationDeleteStyleArgs, cache) => {
      if (input.styleId) {
        cache.invalidate({ __typename: "Style", id: input.styleId });
      }
    },
    updateStyle: (result, { input }: MutationUpdateStyleArgs, cache) => {
      const isStatusUpdate = input.styleId && input.status;

      if (isStatusUpdate) {
        cache.invalidate({ __typename: "Style", id: input.styleId });
      }
    },
    deleteDriveItems: (
      result,
      { input }: MutationDeleteDriveItemsArgs,
      cache,
    ) => {
      input.fileIds?.forEach((fileId) =>
        cache.invalidate({ __typename: "File", id: fileId }),
      );
    },
  },
  Query: {},
};

export default updatesConfig;
