import { DEFAULT_INFERENCE_FORM } from "../../../../features/forge/constants";
import {
  GenerationType,
  InferenceParameters,
  SessionStateInferenceParameters,
} from "../../../../types";
import { ReferenceVideoType } from "../../fields/References/types";
import { formatReferenceVideoForAPI } from "../../fields/References/utils";
import {
  DEFAULT_UPSCALE_VIDEO_FORM_VALUES,
  FORM_FIELDS_UPSCALE_VIDEO,
  FormTypeUpscaleVideo,
  MAX_UPSCALE_DIMENSION,
  MIN_UPSCALE_FACTOR,
} from "./constants";

export const getIsUpscaleTargetFileValid = (targetFile) =>
  targetFile &&
  targetFile.width * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION &&
  targetFile.height * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION;

export const getDefaultFormValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_UPSCALE_VIDEO_FORM_VALUES));

const formatFormValuesForCommonAPI = (
  inputForm: FormTypeUpscaleVideo,
): InferenceParameters => {
  const parameters = {
    ...DEFAULT_INFERENCE_FORM.parameters,
    generationType: "UPSCALE_VIDEO" as GenerationType,
    batchSize: 1,
    upscaleRatio: inputForm[FORM_FIELDS_UPSCALE_VIDEO.UPSCALE_FACTOR],
    files: [],
    styles: [],
  };
  return parameters;
};

export const formatFormValuesForInferenceAPI = (
  inputForm: FormTypeUpscaleVideo,
  refinementVideos?: ReferenceVideoType[],
): InferenceParameters => {
  const parameters = formatFormValuesForCommonAPI(inputForm);
  refinementVideos?.forEach((video) => {
    parameters.files.push(formatReferenceVideoForAPI(video));
  });
  return parameters;
};

export const formatFormValuesForSessionAPI = (
  inputForm: FormTypeUpscaleVideo,
): SessionStateInferenceParameters => {
  const parameters = formatFormValuesForCommonAPI(inputForm);
  return parameters;
};

export const formatInferenceParamsForForm = (
  params: InferenceParameters,
): Partial<FormTypeUpscaleVideo> => {
  const outputForm: Partial<FormTypeUpscaleVideo> = {};

  if (params) {
    if (typeof params.upscaleRatio !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE_VIDEO.UPSCALE_FACTOR] =
        params.upscaleRatio;
    }
  }
  return outputForm;
};
