import { createSvgIcon, SvgIconProps } from "@mui/material";

export const UpscaleIcon = (props: SvgIconProps) => {
  const SvgIcon = createSvgIcon(
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.375 0H3.4375C1.52344 0 0 1.5625 0 3.4375V4.375C0 4.72656 0.273437 5 0.625 5C0.9375 5 1.25 4.72656 1.25 4.375V3.4375C1.25 2.26562 2.22656 1.25 3.4375 1.25H4.375C4.6875 1.25 5 0.976562 5 0.625C5 0.3125 4.6875 0 4.375 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.680257 6.2532C0.661881 6.25109 0.64344 6.25 0.625 6.25C0.273437 6.25 0 6.5625 0 6.875V14.1C0 15.9778 1.52223 17.5 3.4 17.5H10.5L10.5017 17.5H10.625C10.9375 17.5 11.25 17.2266 11.25 16.875C11.25 16.8566 11.2489 16.8381 11.2468 16.8197C11.2489 16.7968 11.25 16.7735 11.25 16.75V9.65C11.25 7.77223 9.72777 6.25 7.85 6.25H0.75C0.726484 6.25 0.703221 6.25108 0.680257 6.2532ZM8.77131 9.35643V13.751C8.77131 14.1041 8.47704 14.3787 8.14352 14.3787C7.79039 14.3787 7.51573 14.1041 7.51573 13.751V10.8867L3.55281 14.83C3.31739 15.085 2.9054 15.085 2.66998 14.83C2.41494 14.5946 2.41494 14.1826 2.66998 13.9471L6.61329 9.98422H3.74899C3.39586 9.98422 3.1212 9.70956 3.1212 9.35643C3.1212 9.02291 3.39586 8.72864 3.74899 8.72864H8.14352C8.47704 8.72864 8.77131 9.02291 8.77131 9.35643Z"
        fill="currentColor"
      />
      <path
        d="M17.5 6.875C17.5 6.5625 17.1875 6.25 16.875 6.25C16.5234 6.25 16.25 6.5625 16.25 6.875V10.625C16.25 10.9766 16.5234 11.25 16.875 11.25C17.1875 11.25 17.5 10.9766 17.5 10.625V6.875Z"
        fill="currentColor"
      />
      <path
        d="M16.25 4.375C16.25 4.72656 16.5234 5 16.875 5C17.1875 5 17.5 4.72656 17.5 4.375V3.4375C17.5 1.5625 15.9375 0 14.0625 0H13.125C12.7734 0 12.5 0.3125 12.5 0.625C12.5 0.976562 12.7734 1.25 13.125 1.25H14.0625C15.2344 1.25 16.25 2.26562 16.25 3.4375V4.375Z"
        fill="currentColor"
      />
      <path
        d="M17.5 14.0625V13.125C17.5 12.8125 17.1875 12.5 16.875 12.5C16.5234 12.5 16.25 12.8125 16.25 13.125V14.0625C16.25 15.2734 15.2344 16.25 14.0625 16.25H13.125C12.7734 16.25 12.5 16.5625 12.5 16.875C12.5 17.2266 12.7734 17.5 13.125 17.5H14.0625C15.9375 17.5 17.5 15.9766 17.5 14.0625Z"
        fill="currentColor"
      />
      <path
        d="M6.875 0C6.52344 0 6.25 0.3125 6.25 0.625C6.25 0.976562 6.52344 1.25 6.875 1.25H10.625C10.9375 1.25 11.25 0.976562 11.25 0.625C11.25 0.3125 10.9375 0 10.625 0H6.875Z"
        fill="currentColor"
      />
    </svg>,
    "UpscaleIcon",
  );

  return <SvgIcon style={{ fontSize: 16, display: "block" }} {...props} />;
};
