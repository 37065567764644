import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { ComponentProps } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getLayerConfig } from "../../utils/config";

type AuthProviderProps = Partial<
  Omit<ComponentProps<typeof Auth0Provider>, "onRedirectCallback">
>;

export const AuthProvider = (props: AuthProviderProps) => {
  const config = getLayerConfig();

  const auth0ProviderOptions: Auth0ProviderOptions = {
    domain: config.AUTH0_DOMAIN,
    clientId: config.AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: config.AUTH0_AUDIENCE,
      redirect_uri: window.location.origin,
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
  };

  const location = useLocation();
  const navigate = useNavigate();

  const onRedirectCallback = (appState: { returnTo?: string } = {}) => {
    navigate(appState.returnTo ?? location.pathname, { replace: true });
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      {...auth0ProviderOptions}
      {...props}
    />
  );
};
