import { BasicUserFragment } from "../../hooks/queries.graphql";
import {
  GenerationType,
  InferenceForm as InferenceFormPayloadType,
} from "../../types";
import {
  SessionAssetFragment,
  SessionFileFragment,
  SessionFileStyleFragment,
} from "./forge-session/queries.graphql";

export type ViewType = "grid" | "list";
export type FilterType = "search" | "tab";
export type Arrow = "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown";
export type AnchorPosition = {
  top: number;
  left: number;
};
export type Modifier = "SHIFT" | "CTRL";
export type FilterTabType = "all" | "favorite";
export type ObjectTabType = "all" | "file" | "asset";

export type ForgeFile = SessionFileFragment & {
  thumbnail: {
    imageUrl: string;
    imageNaturalWidth: number;
  };
};

export type ForgeAsset = SessionAssetFragment & {};

export type ForgeObject = ForgeFile | ForgeAsset;

export interface FileObjectsGroup {
  type: "File";
  id: string;
  header: {
    style: SessionFileStyleFragment;
    prompt: string;
    author: BasicUserFragment;
    generationType: GenerationType;
    width: number;
    height: number;
    upscaleRatio: number;
  };
  objects: ForgeFile[];
}

export interface AssetObjectsGroup {
  type: "Asset";
  id: string;
  header: {
    author: BasicUserFragment;
  };
  objects: ForgeAsset[];
}

export type ObjectsGroup = FileObjectsGroup | AssetObjectsGroup;

export type SessionThumbnailSize =
  (typeof THUMBNAIL_SIZE_SLIDER_OPTIONS)[number];

export const THUMBNAIL_SIZE_SLIDER_OPTIONS: readonly [1, 2, 3, 4, 6, 12] = [
  1, 2, 3, 4, 6, 12,
];

export const FILTERS = {
  SEARCH: "search",
  TAB: "tab",
} as const;

export const DEFAULT_FILTERS_VALUES = {
  [FILTERS.SEARCH]: "",
  [FILTERS.TAB]: "all",
} as const;

export const DEFAULT_INFERENCE_FORM: InferenceFormPayloadType = {
  defaults: {
    initImageWeight: 0.5,
    controlnetImageWeight: 1,
  },
  parameters: {
    generationType: "CREATE",
    batchSize: 4,
    width: 1024,
    height: 1024,
    prompt: "",
    seed: -1,
    numInferenceSteps: 20,
    guidanceScale: 4,
  },
};

export const FORGE_ERROR_DURATION_MS = 4000;
export const NUM_INITIAL_OBJECTS = 8;
export const NUM_ROWS_PER_PAGE = 8;
export const DEFAULT_FORGE_SESSION_NAME = "Untitled";
export const NEW_SESSION_ID = "new";
export const SELECTION_DRAWER_WIDTH = "240px";
export const SELECTION_DRAWER_MARGIN_TOP = "56px";
export const SELECTION_DRAWER_HEIGHT = "calc(100% - 64px)";
export const MEDIUM_THUMBNAIL_CROP = 200;
export const LARGE_THUMBNAIL_CROP = 400;
