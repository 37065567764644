import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

import { useStyleActions } from "../../../../../hooks";
import { Icon, IconButton } from "../../../";

interface StyleTileFooterProps {
  styleId: string;
  styleName: string;
  styleFavorited: boolean;
  workspaceName: string;
  workspaceLogoSrc?: string;
  onActionMenuOpen: (event?: React.MouseEvent) => void;
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(2),
}));

const WorkspaceLogo = styled("img")({
  height: 16,
  width: 16,
  borderRadius: 4,
});

const StyleName = styled(Typography)({
  width: "100%",
});

const FooterLeftsideContainer = styled(Stack)({
  width: "100%",
  overflow: "hidden",
});

export const StyleTileFooter = (props: StyleTileFooterProps) => {
  const {
    styleId,
    styleName,
    styleFavorited,
    workspaceName,
    workspaceLogoSrc,
    onActionMenuOpen,
  } = props;

  const styleActions = useStyleActions();

  const handleFavoriteClick = () => {
    const successMessage = styleFavorited
      ? `${styleName} removed from favorites`
      : `${styleName} added to favorites`;

    styleActions.updateStyle.execute(
      {
        styleId,
        isPinned: !styleFavorited,
      },
      successMessage,
    );
  };

  return (
    <Container>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <FooterLeftsideContainer>
          <Tooltip title={styleName}>
            <StyleName noWrap>{styleName || "No title"}</StyleName>
          </Tooltip>
          <Stack direction="row" spacing={1}>
            {workspaceLogoSrc && (
              <WorkspaceLogo src={workspaceLogoSrc} alt={workspaceName} />
            )}
            <Typography variant="mini" color="secondary.300" noWrap>
              {workspaceName}
            </Typography>
          </Stack>
        </FooterLeftsideContainer>
        <Stack direction="row" alignItems="center">
          <IconButton
            aria-label={styleFavorited ? "Unfavorite Style" : "Favorite Style"}
            variant="quaternary"
            size="small"
            onClick={handleFavoriteClick}
          >
            {styleFavorited && <Icon icon={solid("heart")} />}
            {!styleFavorited && <Icon icon={regular("heart")} />}
          </IconButton>
          <IconButton
            aria-label="Open style options"
            variant="quaternary"
            size="small"
            onClick={onActionMenuOpen}
          >
            <Icon icon={solid("ellipsis-vertical")} />
          </IconButton>
        </Stack>
      </Stack>
    </Container>
  );
};
