import { InferenceQuality } from "../../../../types";
import {
  AUTO_DETECT_PROMPT_LANGUAGE,
  DEFAULT_FACE_LIMIT,
  PromptEnhancementMode,
} from "../../constants";
import { ReferenceImageType } from "../../fields/References/types";
import { FormWithStyle } from "../../fields/Style/types";
import { DEFAULT_FIELDS_STYLE } from "../../fields/Style/utils";
import { FormOption3D } from "./components/Options3D/Options3D";

export const FORM_FIELDS_3D = {
  STYLE: "style",
  TARGET_FILE: "targetFile",
  PROMPT_TEXT: "promptText",
  IMAGE_PROMPT_TEXT: "imagePromptText",
  PROMPT_ENHANCEMENT_MODE: "PromptEnhancementMode",
  NEGATIVE_PROMPT_TEXT: "negativePromptText",
  PROMPT_LANGUAGE: "promptLanguage",
  TEXTURE: "texture",
  PERFORMANCE: "performance",
  FACE_LIMIT: "faceLimit",
  TA_POSE: "ta-pose",
  OPTION: "option",
} as const;

export interface FormType3D extends FormWithStyle {
  [FORM_FIELDS_3D.TARGET_FILE]: ReferenceImageType;
  [FORM_FIELDS_3D.PROMPT_TEXT]?: string;
  [FORM_FIELDS_3D.IMAGE_PROMPT_TEXT]?: string;
  [FORM_FIELDS_3D.PROMPT_ENHANCEMENT_MODE]: PromptEnhancementMode;
  [FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT]?: string;
  [FORM_FIELDS_3D.PROMPT_LANGUAGE]: string;
  [FORM_FIELDS_3D.TEXTURE]: boolean;
  [FORM_FIELDS_3D.FACE_LIMIT]: number;
  [FORM_FIELDS_3D.PERFORMANCE]: InferenceQuality;
  [FORM_FIELDS_3D.TA_POSE]: boolean;
  [FORM_FIELDS_3D.OPTION]: FormOption3D;
}

export const DEFAULT_3D_FORM_VALUES: FormType3D = {
  ...DEFAULT_FIELDS_STYLE,
  [FORM_FIELDS_3D.TARGET_FILE]: null,
  [FORM_FIELDS_3D.PROMPT_TEXT]: "",
  [FORM_FIELDS_3D.IMAGE_PROMPT_TEXT]: "",
  [FORM_FIELDS_3D.PROMPT_ENHANCEMENT_MODE]: "OFF",
  [FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT]: "",
  [FORM_FIELDS_3D.PROMPT_LANGUAGE]: AUTO_DETECT_PROMPT_LANGUAGE,
  [FORM_FIELDS_3D.TEXTURE]: true,
  [FORM_FIELDS_3D.FACE_LIMIT]: DEFAULT_FACE_LIMIT,
  [FORM_FIELDS_3D.PERFORMANCE]: "MEDIUM",
  [FORM_FIELDS_3D.TA_POSE]: false,
  [FORM_FIELDS_3D.OPTION]: "IMAGE",
};
