export const MAX_REMOVE_BG_DIMENSION = 4096;

export const FORM_FIELDS_REMOVE_BG = {
  CROP_TO_BB: "cropToBB",
} as const;

export type RemoveBgFieldValuesType =
  (typeof FORM_FIELDS_REMOVE_BG)[keyof typeof FORM_FIELDS_REMOVE_BG];

export interface FormTypeRemoveBg {
  [FORM_FIELDS_REMOVE_BG.CROP_TO_BB]: boolean;
}

export const DEFAULT_REMOVE_BG_FORM_VALUES: FormTypeRemoveBg = {
  [FORM_FIELDS_REMOVE_BG.CROP_TO_BB]: false,
};
