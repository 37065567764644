import { UseFormReturn } from "react-hook-form";
import { createStore } from "zustand";

import { SessionInferenceMode } from "../../graphql/schema.graphql";
import { RefinementFormMode } from "../common/RefinementForm/constants";
import {
  ReferenceImageType,
  ReferenceVideoType,
} from "../fields/References/types";
import { FormType2D } from "../forms/2D/types";
import { FormType3D } from "../forms/3D/constants";
import { FormTypeRealtime } from "../forms/Realtime/constants";
import { FormTypeRemoveBg } from "../forms/RemoveBG/constants";
import { FormTypeUpscale } from "../forms/Upscale/constants";
import { FormTypeUpscaleVideo } from "../forms/UpscaleVideo/constants";
import { FormTypeVectorize } from "../forms/Vectorize/constants";
import { FormTypeVideo } from "../forms/Video/constants";
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InferenceFormProps {}

export interface InferenceFormInternalProps extends InferenceFormProps {
  activeMode: SessionInferenceMode;
  refinementImages: ReferenceImageType[];
  refinementVideos: ReferenceVideoType[];
  lastVisitedRefinementMode: RefinementFormMode;
  inferenceForm2D: UseFormReturn<FormType2D>;
  inferenceFormUpscale: UseFormReturn<FormTypeUpscale>;
  inferenceFormRemoveBg: UseFormReturn<FormTypeRemoveBg>;
  inferenceFormVectorize: UseFormReturn<FormTypeVectorize>;
  inferenceFormVideo: UseFormReturn<FormTypeVideo>;
  inferenceFormUpscaleVideo: UseFormReturn<FormTypeUpscaleVideo>;
  inferenceForm3D: UseFormReturn<FormType3D>;
  inferenceFormRealtime: UseFormReturn<FormTypeRealtime>;
}

export interface InferenceFormState extends InferenceFormInternalProps {
  setActiveMode: (mode: SessionInferenceMode) => void;
  setRefinementImages: (images: ReferenceImageType[]) => void;
  setRefinementVideos: (videos: ReferenceVideoType[]) => void;
  setLastVisitedRefinementMode: (mode: RefinementFormMode) => void;
  setInferenceForm2D: (form: UseFormReturn<FormType2D>) => void;
  setInferenceForm3D: (form: UseFormReturn<FormType3D>) => void;
  setInferenceFormUpscale: (form: UseFormReturn<FormTypeUpscale>) => void;
  setInferenceFormVectorize: (form: UseFormReturn<FormTypeVectorize>) => void;
  setInferenceFormRemoveBg: (form: UseFormReturn<FormTypeRemoveBg>) => void;
  setInferenceFormVideo: (form: UseFormReturn<FormTypeVideo>) => void;
  setInferenceFormUpscaleVideo: (
    form: UseFormReturn<FormTypeUpscaleVideo>,
  ) => void;
  setInferenceFormRealtime: (form: UseFormReturn<FormTypeRealtime>) => void;
}

export const inferenceFormStore = (initProps: InferenceFormInternalProps) => {
  return createStore<InferenceFormState>()((set) => ({
    ...initProps,
    setActiveMode: (activeMode) => set(() => ({ activeMode })),
    setRefinementImages: (refinementImages) =>
      set(() => ({ refinementImages })),
    setRefinementVideos: (refinementVideos) =>
      set(() => ({ refinementVideos })),
    setLastVisitedRefinementMode: (lastVisitedRefinementMode) =>
      set(() => ({ lastVisitedRefinementMode })),
    setInferenceForm2D: (inferenceForm2D) => set(() => ({ inferenceForm2D })),
    setInferenceForm3D: (inferenceForm3D) => set(() => ({ inferenceForm3D })),
    setInferenceFormUpscale: (inferenceFormUpscale) =>
      set(() => ({ inferenceFormUpscale })),
    setInferenceFormUpscaleVideo: (inferenceFormUpscaleVideo) =>
      set(() => ({ inferenceFormUpscaleVideo })),
    setInferenceFormVectorize: (inferenceFormVectorize) =>
      set(() => ({ inferenceFormVectorize })),
    setInferenceFormRemoveBg: (inferenceFormRemoveBg) =>
      set(() => ({ inferenceFormRemoveBg })),
    setInferenceFormVideo: (inferenceFormVideo) =>
      set(() => ({ inferenceFormVideo })),
    setInferenceFormRealtime: (inferenceFormRealtime) =>
      set(() => ({ inferenceFormRealtime })),
  }));
};

export type InferenceFormStore = ReturnType<typeof inferenceFormStore>;
