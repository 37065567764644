import { PlanTier } from "../components/graphql/schema.graphql";

export type OfferedPlanConstraints = {
  maxMembers: number;
  // inferences
  maxHourlyInferences: number;
  maxMonthlyInferences: number;
  inferenceSpeed: string;
  inferenceWithCustomStyle: boolean;
  // trainings
  maxWorkspaceStyles: number;
  maxWorkspaceStylesPerSeat: number;
  maxConcurrentTrainings: number;
  maxConcurrentTrainingsPerSeat: number;
  maxMonthlyTrainings: number;
  maxMonthlyTrainingsPerSeat: number;
  trainingSpeed: string;
  // styles
  briaStyles: boolean;
  openaiStyles: boolean;
  stabilityaiStyles: boolean;
  urlStyles: boolean;
  bflProStyles: boolean;
};

export type OfferedPlan = {
  tier: PlanTier;
  name: string;
  canUseForPersonalWorkspace?: boolean;
  canUseForSharedWorkspace?: boolean;
  canSelfServe?: boolean;
  freeTrialDays?: number;
  isFree?: boolean;
  monthSubscriptionPrice?: number;
  yearSubscriptionPrice?: number;
  constraints?: OfferedPlanConstraints;
};

interface LayerConfig {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  SENTRY_DSN: string;
  INTERCOM_APP_ID: string;
  OFFERED_PLANS: OfferedPlan[];
  IS_PRODUCTION: boolean;
  DEV_MODE: boolean;
}

declare global {
  interface Window {
    __layerConfig: LayerConfig;
  }
}

const defaultConfig: LayerConfig = {
  AUTH0_DOMAIN: "",
  AUTH0_CLIENT_ID: "",
  AUTH0_AUDIENCE: "",
  SENTRY_DSN: "",
  INTERCOM_APP_ID: "",
  OFFERED_PLANS: [],
  IS_PRODUCTION: false,
  DEV_MODE: false,
};

export const getLayerConfig = (): LayerConfig =>
  window?.__layerConfig || defaultConfig;
