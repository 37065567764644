import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

import { Events } from "../../../../../../../../../../constants/events";
import { useMixpanel } from "../../../../../../../../../../hooks";
import { useTheme } from "../../../../../../../../../ui-v2";

// Release notes
declare module "react" {
  // eslint-disable-next-line
  namespace JSX {
    interface IntrinsicElements {
      "rn-banner": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

declare const rnw;

export const ReleaseNotesBox = () => {
  const theme = useTheme();
  const mixpanel = useMixpanel();
  const bannerRef = useRef(null);
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const bannerLoadedRef = useRef(false);

  useEffect(() => {
    const banner = document.querySelector("rn-banner");
    bannerRef.current = banner;

    const checkBannerLoaded = () => {
      const shadowRoot = banner?.shadowRoot;

      if (shadowRoot && shadowRoot.querySelector(".rn-banner-inner")) {
        if (!bannerLoadedRef.current) {
          bannerLoadedRef.current = true;
          setBannerLoaded(true);
        }
      } else {
        timeoutRef.current = window.setTimeout(checkBannerLoaded, 100);
      }
    };

    if (banner) {
      checkBannerLoaded();
    } else {
      console.error("rn-banner element not found");
    }

    return () => {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (bannerLoaded && bannerRef.current) {
      const banner = bannerRef.current as HTMLElement;
      const shadowRoot = banner.shadowRoot;

      if (!shadowRoot) {
        console.error("Shadow root not found");
        return;
      }

      const select = (className: string): HTMLElement =>
        shadowRoot.querySelector(className);

      const elements = {
        bannerOuter: select(".rn-banner"),
        bannerInner: select(".rn-banner-inner"),
        bannerLeftInner: select(".rn-banner-left-inner"),
        prefix: select(".rn-banner-prefix"),
        link: select(".rn-banner-link"),
        btn: select(".rn-banner-btn"),
        closeBtn: select(".rn-close-btn"),
      };

      // Custom styles to override existing styles
      banner.style.flexGrow = "1";
      banner.style.width = "100%";
      banner.style.transition = "all 300ms ease-out";

      if (elements.bannerOuter) {
        const { bannerOuter, prefix } = elements;
        Object.assign(bannerOuter.style, {
          cursor: "pointer",
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(0),
          backgroundColor: "transparent",
          border: "none",
          boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
          borderRadius: "4px",
          transition: "all 0.1s ease-in-out",
          padding: "8px",
          position: "relative",
        });

        bannerOuter.addEventListener("mouseenter", () => {
          bannerOuter.style.borderColor = theme.palette.secondary[500];
          if (prefix) {
            prefix.style.backgroundColor = theme.palette.primary[900];
            prefix.style.color = theme.palette.primary[50];
          }
        });

        bannerOuter.addEventListener("mouseleave", () => {
          bannerOuter.style.borderColor = theme.palette.secondary[700];
          if (prefix) {
            prefix.style.backgroundColor = theme.palette.primary[950];
            prefix.style.color = theme.palette.primary[300];
          }
        });
      }

      if (elements.bannerInner) {
        Object.assign(elements.bannerInner.style, {
          backgroundColor: "transparent",
          color: theme.palette.secondary[300],
        });
      }

      if (elements.bannerLeftInner) {
        Object.assign(elements.bannerLeftInner.style, {
          display: "flex",
          flexDirection: "column",
        });
      }

      if (elements.prefix) {
        Object.assign(elements.prefix.style, {
          fontWeight: "600",
          width: "fit-content",
          fontSize: `${theme.typography.micro.fontSize}px`,
          backgroundColor: theme.palette.primary[950],
          color: theme.palette.primary[300],
          padding: "2px 8px",
          marginTop: "0px",
          marginLeft: theme.spacing(-1),
          marginBottom: theme.spacing(1),
          transition: "all 0.1s ease-in-out",
        });
        elements.prefix.textContent = "✦ What's New";
      }

      if (elements.link) {
        Object.assign(elements.link.style, {
          width: "169px",
          pointerEvents: "none",
          color: theme.palette.secondary[200],
          fontSize: `${theme.typography.small.fontSize}px`,
          lineHeight: "1.25",
          fontFamily: theme.typography.fontFamily,
          letterSpacing: "0px",
          textDecoration: "none",
        });
      }

      if (elements.btn) {
        elements.btn.style.display = "none";
      }

      if (elements.closeBtn) {
        elements.closeBtn.style.display = "flex";
        elements.closeBtn.style.position = "absolute";
        elements.closeBtn.style.margin = "0px";
        elements.closeBtn.style.right = "6px";
        elements.closeBtn.style.top = "4px";
        elements.closeBtn.style.width = "16px";

        elements.closeBtn.addEventListener("click", (event) => {
          event.stopPropagation();
        });
      }
    }
  }, [bannerLoaded, theme]);

  const handleShowReleaseNotes = (event) => {
    event.preventDefault();
    mixpanel.track(Events.BUTTON_CLICK, {
      type: "release-notes",
    });
    rnw("show");
  };

  return (
    <Box
      sx={{
        flex: "0 0 auto",
        display: "flex",
        alignItems: "end",
        transition: "all 0.3s ease-out",
        marginBottom: "8px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          opacity: bannerLoaded ? 1 : 0,
          transition: "all 0.3s ease-out",
          position: "relative",
          maxHeight: 200,
          width: "100%",
          overflow: "unset",
          borderRadius: "4px",
          backgroundColor: theme.palette.secondary[900],
          boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,

          "&:hover": {
            boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary[600]}`,
          },
        }}
        onClick={handleShowReleaseNotes}
      >
        <rn-banner />

        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0,
            transition: "all 0.3s ease-out",
            pointerEvents: "none",
            borderRadius: "4px",
            padding: "4px",
            cursor: "pointer",
            backgroundColor: "transparent",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              fontWeight: "600",
              fontSize: `${theme.typography.micro.fontSize}px`,
              backgroundColor: theme.palette.primary[950],
              color: theme.palette.primary[300],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "99px",
              userSelect: "none",

              "&:hover": {
                backgroundColor: "rgb(88, 28, 135)",
                color: "rgb(250, 245, 255)",
                boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary[600]}`,
              },
            }}
          >
            &#10022;
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
