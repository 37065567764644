import {
  AUTO_DETECT_PROMPT_LANGUAGE,
  PromptEnhancementMode,
} from "../../constants";
import { STYLE_FIELD_NAME } from "../../fields/Style/constants";
import { FormWithStyle } from "../../fields/Style/types";
import { DEFAULT_FIELDS_STYLE } from "../../fields/Style/utils";

export const MIN_UPSCALE_FACTOR = 2;
export const MAX_UPSCALE_FACTOR = 8;
export const MAX_UPSCALE_DIMENSION = 4096;
export const MIN_RESEMBLANCE = 0;
export const MAX_RESEMBLANCE = 100;
export const MIN_CREATIVITY = 0;
export const MAX_CREATIVITY = 100;

export const FORM_FIELDS_UPSCALE = {
  STYLE: STYLE_FIELD_NAME,
  PROMPT_LANGUAGE: "promptLanguage",
  PROMPT_TEXT: "promptText",
  PROMPT_ENHANCEMENT_MODE: "PromptEnhancementMode",
  NEGATIVE_PROMPT_TEXT: "negativePromptText",
  SEED: "seed",
  RESEMBLANCE: "resemblance",
  CREATIVITY: "creativity",
  UPSCALE_FACTOR: "upscaleFactor",
  DENOISING_STEPS: "denoisingSteps",
  GUIDANCE_SCALE: "guidanceScale",
} as const;

// Create a type that includes all the values from FORM_FIELDS_UPSCALE
export type UpscaleFieldValuesType =
  (typeof FORM_FIELDS_UPSCALE)[keyof typeof FORM_FIELDS_UPSCALE];

export interface FormTypeUpscale extends FormWithStyle {
  [FORM_FIELDS_UPSCALE.UPSCALE_FACTOR]: number;
  [FORM_FIELDS_UPSCALE.PROMPT_LANGUAGE]: string;
  [FORM_FIELDS_UPSCALE.PROMPT_TEXT]?: string;
  [FORM_FIELDS_UPSCALE.PROMPT_ENHANCEMENT_MODE]: PromptEnhancementMode;
  [FORM_FIELDS_UPSCALE.NEGATIVE_PROMPT_TEXT]?: string;
  [FORM_FIELDS_UPSCALE.RESEMBLANCE]: number;
  [FORM_FIELDS_UPSCALE.CREATIVITY]: number;
  [FORM_FIELDS_UPSCALE.SEED]?: number;
  [FORM_FIELDS_UPSCALE.GUIDANCE_SCALE]?: number;
  [FORM_FIELDS_UPSCALE.DENOISING_STEPS]: number;
}

export const DEFAULT_UPSCALE_FORM_VALUES: FormTypeUpscale = {
  ...DEFAULT_FIELDS_STYLE,
  [FORM_FIELDS_UPSCALE.UPSCALE_FACTOR]: 2,
  [FORM_FIELDS_UPSCALE.PROMPT_LANGUAGE]: AUTO_DETECT_PROMPT_LANGUAGE,
  [FORM_FIELDS_UPSCALE.PROMPT_TEXT]: "",
  [FORM_FIELDS_UPSCALE.PROMPT_ENHANCEMENT_MODE]: "OFF",
  [FORM_FIELDS_UPSCALE.NEGATIVE_PROMPT_TEXT]: "",
  [FORM_FIELDS_UPSCALE.RESEMBLANCE]: 60,
  [FORM_FIELDS_UPSCALE.CREATIVITY]: 35,
  [FORM_FIELDS_UPSCALE.SEED]: null,
  [FORM_FIELDS_UPSCALE.DENOISING_STEPS]: 10,
  [FORM_FIELDS_UPSCALE.GUIDANCE_SCALE]: 4,
};
