import styled from "@emotion/styled";

export const AnimatedBorder = styled.div<{
  bgColor: string;
  gradientStart: string;
  gradientEnd: string;
}>`
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }

  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(
    from var(--border-angle),
    ${({ bgColor }) => bgColor},
    ${({ bgColor }) => bgColor} 5%,
    ${({ bgColor }) => bgColor} 60%,
    ${({ bgColor }) => bgColor} 95%
  );
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    ${({ gradientStart }) => gradientStart},
    ${({ gradientEnd }) => gradientEnd} 99%,
    transparent
  );

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 3px transparent;
  border-radius: 2px;
  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;
  background-position: center center;
  animation: bg-spin 3s linear infinite;

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
`;
