import { DimensionsType } from "../fields/AspectRatio/types";
import {
  ReferenceImageType,
  ReferenceVideoType,
} from "../fields/References/types";

export const clip = (input: number, min: number, max: number) => {
  return Math.min(Math.max(input, min), max);
};

export const getImageObjectSafe = async (
  imageUrl: string,
): Promise<HTMLImageElement | null> => {
  try {
    return await getImageObject(imageUrl);
  } catch {
    return null;
  }
};

export const getImageObject = (imageUrl: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = imageUrl;
  });

export const getVideoObject = (videoUrl: string): Promise<HTMLVideoElement> =>
  new Promise<HTMLVideoElement>((resolve, reject) => {
    const video = document.createElement("video");
    video.onloadedmetadata = () => {
      // Ensure width and height are available
      if (video.videoWidth && video.videoHeight) {
        resolve(video);
      } else {
        reject(new Error("Video dimensions not available"));
      }
    };
    video.onerror = (err) => reject(err);

    // Handle data URLs by converting to blob URL
    if (videoUrl.startsWith("data:")) {
      try {
        // Extract base64 data
        const base64Data = videoUrl.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "video/mp4" });
        const blobUrl = URL.createObjectURL(blob);

        video.src = blobUrl;
        // Clean up the blob URL when the video loads
        video.onloadedmetadata = () => {
          if (video.videoWidth && video.videoHeight) {
            URL.revokeObjectURL(blobUrl);
            resolve(video);
          } else {
            URL.revokeObjectURL(blobUrl);
            reject(new Error("Video dimensions not available"));
          }
        };
      } catch (error) {
        reject(new Error(`Failed to process data URL: ${error.message}`));
      }
    } else {
      // Handle regular URLs
      video.crossOrigin = "anonymous";
      video.src = videoUrl;
    }
  });

export const getImageReferenceFromDownloadUrl = async (
  downloadUrl: string,
): Promise<ReferenceImageType> => {
  const htmlImageObject = await getImageObject(downloadUrl);
  return {
    src: downloadUrl,
    width: htmlImageObject.width,
    height: htmlImageObject.height,
  };
};

export const getVideoReferenceFromDownloadUrl = async (
  downloadUrl: string,
): Promise<ReferenceVideoType> => {
  const htmlVideoObject = await getVideoObject(downloadUrl);
  return {
    src: downloadUrl,
    width: htmlVideoObject.videoWidth,
    height: htmlVideoObject.videoHeight,
  };
};

export const getWeightFromPercentage = (percentage: number) => {
  return Math.round((percentage / 100) * 100) / 100;
};
export const getPercentageFromWeight = (weight: number) => {
  return Math.round(weight * 100);
};
export const getWeightFromSimilarity = (similarity: number) => {
  return Math.round((similarity / 100) * 20) / 20;
};
export const getSimilarityFromWeight = (weight: number) => {
  return Math.round(weight * 100);
};

export const getPixelDensityFromSliderValue = (slider: number) => {
  return Math.round(Math.min(Math.max(slider, 1), 4) / 0.5) * 0.5;
};
export function getSliderValueFromPixelDensity(pixelDensity: number) {
  if (!pixelDensity) return 1;
  return Math.min(Math.max(pixelDensity, 1), 4);
}

export const getDimensionsString = (dimensions: DimensionsType) =>
  `${dimensions[0]}x${dimensions[1]}`;

// Needed to center the preview rectangle correctly in the container
export const getPreviewRectangleContainerDirection = (
  dimensions: DimensionsType,
): string => (dimensions[0] > dimensions[1] ? "row" : "column");

export const formatAPIPromptLanguageForForm = (promptLanguage: string) =>
  promptLanguage === "en" ? "" : promptLanguage;
