import { SyntheticEvent } from "react";
import { Controller } from "react-hook-form";

import { StyleCapabilities } from "../../../../../../types";
import { InferenceStyleFragment } from "../../../../../inference/queries.graphql";
import { DisabledArea, Tab, Tabs } from "../../../../../ui-v2";
import { FORM_FIELDS_3D } from "../../constants";

export type FormOption3D = "IMAGE" | "TEXT";
export const FORM_OPTIONS_3D: FormOption3D[] = ["IMAGE", "TEXT"] as const;

const OptionToNameMap3D = new Map<FormOption3D, string>([
  ["IMAGE", "Image to Model"],
  ["TEXT", "Text to Model"],
]);

const isOptionEnabled = (
  option: FormOption3D,
  styleCapabilities: StyleCapabilities,
) => {
  if (option === "IMAGE") {
    return styleCapabilities?.imageTo3D;
  }
  if (option === "TEXT") {
    return styleCapabilities?.textTo3D;
  }

  return false;
};

interface OptionSelect3DProps {
  onChange: () => void;
  style: InferenceStyleFragment;
}

export const OptionSelect3D = (props: OptionSelect3DProps) => {
  const { onChange, style } = props;
  return (
    <Controller
      name={FORM_FIELDS_3D.OPTION}
      render={({ field }) => {
        // It's difficult to disable a single tab with a tooltip indicator so instead
        // leave the tab enabled but disable the click event
        const handleOnTabChange = (_: SyntheticEvent, newTab: any) => {
          if (isOptionEnabled(newTab, style?.capabilities)) {
            field.onChange(newTab);
            field.onBlur();
            onChange();
          }
        };
        return (
          <Tabs
            underline
            {...field}
            onChange={handleOnTabChange}
            sx={{
              pt: 1,
            }}
          >
            {FORM_OPTIONS_3D.map((option) => {
              const disabled = !isOptionEnabled(option, style?.capabilities);
              return (
                <Tab
                  key={option}
                  value={option}
                  label={
                    <DisabledArea
                      disabled={disabled}
                      title={`This style does not support ${OptionToNameMap3D.get(option)}`}
                      arrow
                      placement="right"
                    >
                      {OptionToNameMap3D.get(option)}
                    </DisabledArea>
                  }
                />
              );
            })}
          </Tabs>
        );
      }}
    />
  );
};
