import {
  Box,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha, lighten } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";

import { Tooltip, TooltipProps } from "..";

export interface IconButtonProps extends MuiIconButtonProps {
  "aria-label": string;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "colored"
    | "error"
    | "white";
  href?: string;
  size?: "small" | "medium" | "large";
  loading?: boolean;
  tooltip?: boolean;
  tooltipProps?: Partial<TooltipProps>;
  rounded?: boolean;
}

const Palette = {
  PRIMARY: {
    BACKGROUND: alpha("#52526F", 0.5),
    BORDER: alpha("#52526F", 0.25),
  },
  SECONDARY: {
    BACKGROUND: "#232434",
    BORDER: "#2C2D3C",
  },
  TERTIARY: {
    BACKGROUND: alpha("#191A23", 0.75),
    BORDER: "#2C2D3C",
  },
  QUATERNARY: {
    BACKGROUND: "transparent",
    BORDER: "transparent",
  },
};

const extraProps: PropertyKey[] = ["rounded", "variant"];

const StyledButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<IconButtonProps>(({ variant, rounded, theme }) => {
  const variantStyles = {
    primary: {
      borderColor: Palette.PRIMARY.BORDER,
      backgroundColor: Palette.PRIMARY.BACKGROUND,

      "&:hover": {
        borderColor: lighten(Palette.PRIMARY.BORDER, 0.2),
        backgroundColor: lighten(Palette.PRIMARY.BACKGROUND, 0.2),
      },
    },
    secondary: {
      borderColor: Palette.SECONDARY.BORDER,
      backgroundColor: Palette.SECONDARY.BACKGROUND,

      "&:hover": {
        borderColor: lighten(Palette.SECONDARY.BORDER, 0.2),
        backgroundColor: lighten(Palette.SECONDARY.BACKGROUND, 0.2),
      },
    },
    tertiary: {
      borderColor: Palette.TERTIARY.BORDER,
      backgroundColor: Palette.TERTIARY.BACKGROUND,

      "&:hover": {
        borderColor: lighten(Palette.TERTIARY.BORDER, 0.2),
        backgroundColor: lighten(Palette.TERTIARY.BACKGROUND, 0.2),
      },
    },
    quaternary: {
      borderColor: Palette.QUATERNARY.BORDER,
      backgroundColor: Palette.QUATERNARY.BORDER,
    },
    colored: {
      backgroundColor: theme.palette.primary[600],
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary[600],
      },
      "&:hover": {
        backgroundColor: theme.palette.primary[500],
      },
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary[600],
      },
      "&:hover": {
        backgroundColor: theme.palette.error[700],
      },
    },
    white: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,

      "&:hover": {
        backgroundColor: theme.palette.grey[400],
      },
    },
  };

  const styles = variantStyles[variant];

  if (rounded) {
    Object.assign(styles, {
      borderRadius: "50%",
    });
  }

  return styles;
});

const LoaderWrapper = styled("span")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
});

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      size = "medium",
      variant = "primary",
      rounded = false,
      tooltip = true,
      tooltipProps,
      disabled = false,
      loading = false,
      children,
      ...rest
    }: IconButtonProps,
    ref,
  ) => {
    const buttonEl = (
      <StyledButton
        {...rest}
        size={size}
        variant={variant}
        rounded={rounded}
        ref={ref}
        disabled={loading || disabled}
      >
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={20} />
          </LoaderWrapper>
        )}
        {children}
      </StyledButton>
    );

    if (tooltip) {
      return (
        <Tooltip title={rest["aria-label"]} {...tooltipProps} enterDelay={300}>
          {/* Wrap the button in a Box to show a tooltip even if the button is disabled */}
          <Box display="inline-block">{buttonEl}</Box>
        </Tooltip>
      );
    }

    return buttonEl;
  },
);
