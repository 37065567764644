import { Box } from "@mui/system";

import { PresetType } from "../../types";

export const PresetIcon = ({ preset }: { preset: PresetType }) => {
  return (
    <Box
      minWidth={24}
      minHeight={18}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ borderColor: "inherit" }}
    >
      <Box
        sx={{
          aspectRatio: preset.aspectRatio,
          border: "1px solid",
          borderColor: "inherit",
          maxWidth: "14px",
          flex: 1,
        }}
      />
    </Box>
  );
};
