import { Box, styled } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Typography } from "../../../../../../../../../ui-v2";

interface PrimaryNavigationLinkProps {
  active: boolean;
  to: string;
  label: string;
  icon: ReactNode;
  activeIcon: ReactNode;
}

const Link = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme }) => ({
  height: theme.spacing(9),
  display: "flex",
  flexGrow: 1,
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  color: theme.palette.secondary[300],
  border: "none",
  boxShadow: "inset 0 0 0 0.5px transparent",

  "&:hover": {
    backgroundColor: theme.palette.secondary[900],
    boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
  },

  variants: [
    {
      props: ({ active }) => active,
      style: {
        color: theme.palette.secondary[50],
        backgroundColor: theme.palette.secondary[900],
        boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
      },
    },
  ],
}));

const IconContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
}));

export const PrimaryNavigationLink = (props: PrimaryNavigationLinkProps) => {
  const { active, to, label, icon, activeIcon } = props;
  return (
    <Link
      active={active}
      to={to}
      data-product-tour={`nav-${label.toLowerCase()}`}
    >
      <IconContainer>{active ? activeIcon : icon}</IconContainer>
      <Typography variant="small" fontWeight={500} color="secondary.100">
        {label}
      </Typography>
    </Link>
  );
};
