import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "../components/ui-v2";

const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile;
};

export default useIsMobile;
