import {
  AUTO_DETECT_PROMPT_LANGUAGE,
  DEFAULT_DENSITY,
  DEFAULT_REFERENCE_SIMILARITY,
} from "../../constants";
import { STYLE_FIELD_NAME } from "../../fields/Style/constants";
import { DEFAULT_FIELDS_STYLE } from "../../fields/Style/utils";
import { FormType2D } from "./types";

export const FORM_FIELDS_2D = {
  STYLE: STYLE_FIELD_NAME,
  PROMPT_LANGUAGE: "promptLanguage",
  PROMPT_TEXT: "promptText",
  PROMPT_ENHANCEMENT_MODE: "PromptEnhancementMode",
  PROMPT_PREFIX: "promptPrefix",
  PROMPT_SUFFIX: "promptSuffix",
  NEGATIVE_PROMPT_TEXT: "negativePromptText",
  PERFORMANCE: "performance",
  SEED: "seed",
  DENOISING_STEPS: "denoisingSteps",
  TRANSPARENCY: "transparency",
  TILEABILITY: "tileability",
  DIMENSIONS: "dimensions",
  REFERENCES: "references",
  DENSITY: "density",
  CANVAS_SELECTION: "canvasSelection",
  BASE_SHIFT: "baseShift",
  MAX_SHIFT: "maxShift",
  GUIDANCE_SCALE: "guidanceScale",
  BATCH_SIZE: "batchSize",
} as const;

export const DEFAULT_2D_FORM_VALUES: FormType2D = {
  ...DEFAULT_FIELDS_STYLE,
  [FORM_FIELDS_2D.PROMPT_LANGUAGE]: AUTO_DETECT_PROMPT_LANGUAGE,
  [FORM_FIELDS_2D.PROMPT_TEXT]: "",
  [FORM_FIELDS_2D.PROMPT_ENHANCEMENT_MODE]: "OFF",
  [FORM_FIELDS_2D.PROMPT_PREFIX]: null,
  [FORM_FIELDS_2D.PROMPT_SUFFIX]: null,
  [FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT]: "",
  [FORM_FIELDS_2D.PERFORMANCE]: "MEDIUM",
  [FORM_FIELDS_2D.SEED]: null,
  [FORM_FIELDS_2D.DENOISING_STEPS]: 10,
  [FORM_FIELDS_2D.TRANSPARENCY]: false,
  [FORM_FIELDS_2D.TILEABILITY]: false,
  [FORM_FIELDS_2D.DIMENSIONS]: [1024, 1024],
  [FORM_FIELDS_2D.REFERENCES]: [],
  [FORM_FIELDS_2D.BASE_SHIFT]: 0.5,
  [FORM_FIELDS_2D.MAX_SHIFT]: 1.15,
  [FORM_FIELDS_2D.GUIDANCE_SCALE]: 4,
  [FORM_FIELDS_2D.BATCH_SIZE]: 4,
  [FORM_FIELDS_2D.CANVAS_SELECTION]: {
    type: "INIT",
    image: null,
    similarity: DEFAULT_REFERENCE_SIMILARITY,
    density: DEFAULT_DENSITY,
  },
};
