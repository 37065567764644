import { createSvgIcon, SvgIconProps } from "@mui/material";

export const RemoveBackgroundIcon = (props: SvgIconProps) => {
  const SvgIcon = createSvgIcon(
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.375 0H3.4375C1.52344 0 0 1.5625 0 3.4375V4.375C0 4.72656 0.273437 5 0.625 5C0.9375 5 1.25 4.72656 1.25 4.375V3.4375C1.25 2.26562 2.22656 1.25 3.4375 1.25H4.375C4.6875 1.25 5 0.976562 5 0.625C5 0.3125 4.6875 0 4.375 0Z"
        fill="currentColor"
      />
      <path
        d="M1.25 6.875C1.25 6.5625 0.9375 6.25 0.625 6.25C0.273437 6.25 0 6.5625 0 6.875V10.625C0 10.9766 0.273437 11.25 0.625 11.25C0.9375 11.25 1.25 10.9766 1.25 10.625V6.875Z"
        fill="currentColor"
      />
      <path
        d="M17.5 6.875C17.5 6.5625 17.1875 6.25 16.875 6.25C16.5234 6.25 16.25 6.5625 16.25 6.875V10.625C16.25 10.9766 16.5234 11.25 16.875 11.25C17.1875 11.25 17.5 10.9766 17.5 10.625V6.875Z"
        fill="currentColor"
      />
      <path
        d="M16.25 4.375C16.25 4.72656 16.5234 5 16.875 5C17.1875 5 17.5 4.72656 17.5 4.375V3.4375C17.5 1.5625 15.9375 0 14.0625 0H13.125C12.7734 0 12.5 0.3125 12.5 0.625C12.5 0.976562 12.7734 1.25 13.125 1.25H14.0625C15.2344 1.25 16.25 2.26562 16.25 3.4375V4.375Z"
        fill="currentColor"
      />
      <path
        d="M17.5 14.0625V13.125C17.5 12.8125 17.1875 12.5 16.875 12.5C16.5234 12.5 16.25 12.8125 16.25 13.125V14.0625C16.25 15.2734 15.2344 16.25 14.0625 16.25H13.125C12.7734 16.25 12.5 16.5625 12.5 16.875C12.5 17.2266 12.7734 17.5 13.125 17.5H14.0625C15.9375 17.5 17.5 15.9766 17.5 14.0625Z"
        fill="currentColor"
      />
      <path
        d="M1.25 14.0625V13.125C1.25 12.8125 0.9375 12.5 0.625 12.5C0.273437 12.5 0 12.8125 0 13.125V14.0625C0 15.9766 1.52344 17.5 3.4375 17.5H4.375C4.6875 17.5 5 17.2266 5 16.875C5 16.5625 4.6875 16.25 4.375 16.25H3.4375C2.22656 16.25 1.25 15.2734 1.25 14.0625Z"
        fill="currentColor"
      />
      <path
        d="M6.25 16.875C6.25 17.2266 6.52344 17.5 6.875 17.5H10.625C10.9375 17.5 11.25 17.2266 11.25 16.875C11.25 16.5625 10.9375 16.25 10.625 16.25H6.875C6.52344 16.25 6.25 16.5625 6.25 16.875Z"
        fill="currentColor"
      />
      <path
        d="M6.875 0C6.52344 0 6.25 0.3125 6.25 0.625C6.25 0.976562 6.52344 1.25 6.875 1.25H10.625C10.9375 1.25 11.25 0.976562 11.25 0.625C11.25 0.3125 10.9375 0 10.625 0H6.875Z"
        fill="currentColor"
      />
      <path
        d="M6.43168 6.02494C6.13945 6.512 5.6329 6.80423 5.06791 6.80423C4.52241 6.80423 4.01586 6.512 3.72363 6.02494C3.45087 5.55736 3.45087 4.9534 3.72363 4.46634C4.01586 3.99876 4.52241 3.68705 5.06791 3.68705C5.6329 3.68705 6.13945 3.99876 6.43168 4.46634C6.70444 4.9534 6.70444 5.55736 6.43168 6.02494Z"
        fill="currentColor"
      />
      <path
        d="M14.9845 13.8254C14.9845 14.4099 14.4974 14.8969 13.9129 14.8969H3.72363C3.06122 14.8969 2.51572 14.3709 2.51572 13.7085C2.51572 13.4942 2.59365 13.2799 2.69106 13.1045L5.26274 8.87685C5.34067 8.75996 5.49653 8.66255 5.6329 8.66255C5.78876 8.66255 5.92514 8.75996 6.02255 8.87685L6.95771 10.416L9.41249 6.5C9.52939 6.30518 9.76318 6.1688 9.99697 6.1688C10.2502 6.1688 10.484 6.30518 10.6009 6.5L14.8286 13.2409C14.926 13.4163 14.9845 13.6111 14.9845 13.8254Z"
        fill="currentColor"
      />
    </svg>,
    "RemoveBackgroundIcon",
  );

  return <SvgIcon style={{ fontSize: 16, display: "block" }} {...props} />;
};
