import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Stack } from "@mui/system";

import { getAssetURL } from "../../utils/assets";
import { Button, Icon, Link, Typography } from "../ui-v2";

const helpLinks = [
  {
    name: "Status",
    href: "https://status.layer.ai/",
    description: "Check out our status page for any incidents.",
    icon: (
      <Icon
        icon={icon({
          name: "signal-bars",
          family: "classic",
          style: "solid",
        })}
      />
    ),
  },
  {
    name: "Guides",
    href: "https://help.layer.ai/en/",
    description: "Guides that cover popular requests.",
    icon: (
      <Icon
        icon={icon({
          name: "book-bookmark",
          family: "sharp",
          style: "solid",
        })}
      />
    ),
  },
  {
    name: "Logout",
    href: "/logout",
    description:
      "Logging out and back in may help if you're repeatedly facing this issue.",
    icon: (
      <Icon
        icon={icon({
          name: "arrow-right-from-bracket",
          family: "sharp",
          style: "solid",
        })}
      />
    ),
  },
];

interface ErrorMessageProps {
  title: string;
  description: string;
  showHelpLinks?: boolean;
  errorData?: Error;
}

export default function ErrorMessage({
  title,
  description,
  showHelpLinks,
  errorData,
}: ErrorMessageProps) {
  return (
    <Stack
      sx={{ width: "100%", height: "100%", padding: 4 }}
      direction="column"
      spacing={4}
      justifyContent="center"
      alignItems="center"
    >
      <Link href="/">
        <img
          style={{ width: "auto", height: "64px" }}
          src={getAssetURL("branding/mark_color.png")}
          alt="Layer AI"
        />
      </Link>
      <Typography variant="title2" fontWeight={700} textAlign="center">
        {title}
      </Typography>
      <Typography
        variant="large"
        color="secondary.300"
        textAlign="center"
        sx={{
          pt: 4,
          maxWidth: "42rem",
        }}
      >
        {description}
      </Typography>
      {errorData ? (
        <Box
          sx={{
            padding: 4,
            width: "100%",
          }}
        >
          <Box
            component="pre"
            sx={{
              fontSize: 12,
              color: "secondary.300",
              backgroundColor: "secondary.800",
              overflowY: "auto",
              padding: 4,
              borderRadius: 4,
            }}
          >
            {errorData.stack ?? Error.prototype.toString.call(errorData)}
          </Box>
        </Box>
      ) : null}
      {showHelpLinks && (
        <Stack
          direction="column"
          spacing={2}
          sx={{
            pt: 8,
          }}
        >
          {helpLinks.map((link, linkIdx) => (
            <Stack
              key={linkIdx}
              component={Link}
              href={link.href}
              direction="row"
              alignItems="center"
              spacing={6}
              sx={{
                padding: 6,
                border: "1px solid",
                borderColor: "secondary.700",
                borderRadius: 4,
                "&:hover": {
                  backgroundColor: "secondary.800",
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  border: "1px solid",
                  borderColor: "secondary.700",
                  borderRadius: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {link.icon}
              </Box>
              <Stack
                direction="column"
                spacing={1}
                component="span"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="regular"
                  color="secondary.200"
                  fontWeight={700}
                >
                  <Link href={link.href}>{link.name}</Link>
                </Typography>
                <Typography variant="regular" color="secondary.300">
                  {link.description}
                </Typography>
              </Stack>
              <Box
                component="span"
                sx={{
                  color: "secondary.400",
                }}
              >
                <Icon
                  icon={icon({
                    name: "chevron-right",
                    family: "sharp",
                    style: "solid",
                  })}
                />
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
      <Box
        sx={{
          pt: 8,
        }}
      >
        <Button
          component={Link}
          href="/"
          variant="text"
          color="primary"
          startIcon={
            <Icon
              icon={icon({
                name: "arrow-left",
                family: "sharp",
                style: "solid",
              })}
            />
          }
        >
          Back to home
        </Button>
      </Box>
    </Stack>
  );
}
