import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { REFINEMENT_MODES } from "@/components/inference-forms/common/RefinementForm/constants";

import { useBasicData } from "../../../../hooks";
import { SessionInferenceMode } from "../../../graphql/schema.graphql";
import { useInferenceFormStore } from "../../../inference-forms/state";
import { Icon, IconButton } from "../../../ui-v2";
import { Icon2D, Icon3D, IconVideo, RealtimeIcon } from "../../../ui-v2/icons";
import { SESSION_NAVIGATION_WIDTH } from "../../constants";
import { useNavigationStore } from "../../state";
import { SessionNavigationItem } from "./components/SessionNavigationItem/SessionNavigationItem";

const Container = styled("nav")(({ theme }) => ({
  padding: theme.spacing(3, 0),
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(2),
  position: "fixed",
  width: SESSION_NAVIGATION_WIDTH,
  top: theme.spacing(2),
  left: theme.spacing(2),
  bottom: theme.spacing(2),
  overflow: "auto",
  borderRight: `1px solid ${theme.palette.secondary[600]}`,
}));

const StyledNavigationToggle = styled(IconButton)(({ theme }) => ({
  "& > svg": {
    color: theme.palette.secondary["300"],
  },
}));

export const SessionNavigation = () => {
  const { featureFlags } = useBasicData();
  const { setExpanded } = useNavigationStore();
  const { activeMode, setActiveMode, lastVisitedRefinementMode } =
    useInferenceFormStore();

  const handleOpen = () => {
    setExpanded(true);
  };

  const handleClick = (newMode: SessionInferenceMode) => () => {
    setActiveMode(newMode);
  };

  return (
    <Container>
      <StyledNavigationToggle
        aria-label="Open navigation"
        onClick={handleOpen}
        variant="quaternary"
      >
        <Icon icon={solid("sidebar")} size="large" />
      </StyledNavigationToggle>
      <SessionNavigationItem
        label="Realtime"
        icon={<RealtimeIcon />}
        active={activeMode === "REALTIME"}
        onClick={handleClick("REALTIME")}
      />
      <SessionNavigationItem
        label="2D"
        icon={<Icon2D />}
        active={activeMode === "CREATE"}
        onClick={handleClick("CREATE")}
      />
      <SessionNavigationItem
        label="3D"
        icon={<Icon3D />}
        active={activeMode === "CREATE_3D"}
        onClick={handleClick("CREATE_3D")}
      />
      {featureFlags.showVideoMode && (
        <SessionNavigationItem
          label="Video"
          icon={<IconVideo />}
          active={activeMode === "CREATE_VIDEO"}
          onClick={handleClick("CREATE_VIDEO")}
        />
      )}
      <SessionNavigationItem
        label="Refine"
        icon={<Icon size="large" icon={solid("sparkles")} />}
        active={REFINEMENT_MODES.includes(activeMode)}
        onClick={handleClick(lastVisitedRefinementMode || "UPSCALE")}
      />
    </Container>
  );
};
