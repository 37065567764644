export const Events = {
  NETWORK_REQUEST: "Network Request",
  NETWORK_ERROR: "Network Error",
  LINK_CLICK: "Link Clicked",
  BUTTON_CLICK: "Button Clicked",
  SHORTCUT_USE: "Shortcut Used",
  FORGE_SETTINGS_CHANGE: "Forge Settings Changed",
  // Forging
  FORGE_CLICK: "Forge Clicked",
  FORGE_START: "Forge Start",
  FORGE_ERROR: "Forge Error",
  FORGE_SHOW_ADVANCED_OPTIONS: "Forge show advanced options",
  FORGE_HIDE_ADVANCED_OPTIONS: "Forge hide advanced options",

  // Forge References
  FORGE_REFERENCE_SELECT: "Forge Reference Select",
  FORGE_REFERENCE_FILE_SELECT: "Forge Reference File Select",

  // Image/Video/Mesh references
  TARGET_MEDIA_SELECT: "Target Media Selected",

  // Video
  VIDEO_DURATION_CHANGE: "Video Duration Changed",

  // Poser
  POSE_USE: "Pose Use",
  POSE_CREATE: "Pose Create",
  POSE_DUPLICATE: "Pose Duplicate",
  POSE_DELETE: "Pose Delete",
  POSE_EDIT: "Pose Edit",

  // Realtime canvas
  REALTIME_CLEAR_CANVAS: "Clear Realtime Canvas",
  REALTIME_CHANGE_BACKGROUND_COLOR: "Set Realtime Canvas Background Color",
  REALTIME_CHANGE_BACKGROUND_IMAGE: "Set Realtime Canvas Background Image",
  REALTIME_OPEN_IN_CANVAS: "Open Realtime Sketch in Canvas",
  REALTIME_UPSCALE: "Upscale Realtime Sketch",
  REALTIME_CREATE_3D: "Forge 3D Model from Realtime Sketch",
  REALTIME_CREATE_VARIATIONS: "Forge Variations from Realtime Sketch",
  REALTIME_SAVE_TO_SESSION: "Save Realtime Sketch to Session",
  REALTIME_SAVE_TO_DRIVE: "Save Realtime Sketch to Drive",
  REALTIME_DOWNLOAD: "Download Realtime Sketch",
  REALTIME_RESEED: "Set New Seed for Realtime Canvas",
  REALTIME_PAUSE_PLAY: "Toggle Pause/Play for Realtime Canvas",
  REALTIME_TOGGLE_HISTORY: "Toggle History Visibility for Realtime Canvas",
  REALTIME_START_SCREENSHARE: "Start Realtime Canvas Screenshare",
  REALTIME_STOP_SCREENSHARE: "Stop Realtime Canvas Screenshare",
  REALTIME_SELECT_TOOL: "Select Tool for Realtime Canvas",
  REALTIME_USED_SHORTCUT: "Used a shortcut in Realtime Canvas",
  REALTIME_PASTE_IMAGE: "Pastes image in Realtime Canvas",
  REALTIME_GENERATE_IMAGE: "Generates image in Realtime Canvas",

  // Styles
  STYLE_CREATE: "Style Created",
  STYLE_CREATE_DRAFT: "Style Draft Created",
  STYLE_CREATE_DRAFT_FROM_UPLOAD: "Style Created from Newly Uploaded Model",
  STYLE_CREATE_DRAFT_FROM_MODEL: "Style Created from Existing Model in Drive",
  STYLE_ARCHIVE_FROM_STUDIO: "Style Archived from Studio",
  STYLE_UNARCHIVE_FROM_STUDIO: "Style Unarchived from Studio",
  STYLE_DELETE_FROM_STUDIO: "Style Deleted from Studio",
  STYLE_CREATE_TRIGGER_ERROR: "Style Creation Cannot Start",
  STYLE_DUPLICATE_FROM_STUDIO: "Style Duplicated from Studio",
  STYLE_EDIT_FROM_STUDIO: "Navigated to Style Edit View from Studio",
  STYLE_FORGE_FROM_STUDIO: "Navigated to Forge With Style from Studio",
  STYLE_LOCATE_FROM_STUDIO: "Navigated to Style in Drive from Studio",
  STYLE_IMPORTED_FROM_STUDIO: "Style Imported from Studio",
  STYLE_UNLINKED_FROM_STUDIO: "Style Unlinked from Studio",
  STYLE_CANCEL_TRAINING: "Style Training Canceled",
  STUDIO_UPGRADE: "Upgrade to Pro Clicked from Studio",
  STYLE_DROPDOWN_SELECTION: "Selected Style from Inference Form Dropdown",
  // Sessions
  SESSION_CREATE: "Session Created",
  SESSION_OPEN: "Session Opened",
  SESSION_ARCHIVE: "Session Archived",
  SESSION_DELETE: "Session Deleted",
  SESSION_RENAMED_HOMEPAGE: "Session Renamed from Homepage",
  SESSION_NAME_CLICKED_BREADCRUMBS:
    "Session Name Clicked from Session Breadcrumbs",
  SESSION_RENAMED_BREADCRUMBS: "Session Renamed from Session Breadcrumbs",
  SESSION_FILES_LIKED: "Session Files Liked",
  SESSION_FILES_SAVED_TO_DRIVE: "Session Files Saved to Drive",
  SESSIONS_PREVIEW_FILE: "Session Preview File",
  PREVIEW_FILE: "Preview File Clicked",
  // Homepage
  CLICK_UPGRADE_BANNER_PRO: "Homepage Upgrade to Pro Banner Clicked",
  CLICK_UPGRADE_BANNER_STUDIO: "Homepage Upgrade to Studio Banner Clicked",
  CLICK_UPGRADE_BANNER_ENTERPRISE:
    "Homepage Upgrade to Enterprise Banner Clicked",
  HOMEPAGE_NAVIGATION_CARD_CLICKED: "Homepage Navigation Card Clicked",
  CLICK_ADD_FLUX_TO_WORKSPACE: "Homepage Add Flux banner Clicked",
  CLICK_START_USING_FLUX: "Homepage Start Using Flux banner Clicked",
};
