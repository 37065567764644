import { DEFAULT_INFERENCE_FORM } from "../../../../features/forge/constants";
import {
  GenerationType,
  InferenceParameters,
  SessionStateInferenceParameters,
} from "../../../../types";
import { ReferenceImageType } from "../../fields/References/types";
import { formatReferenceImageForAPI } from "../../fields/References/utils";
import { formatInferenceParamsForFormWithStyle } from "../../fields/Style/utils";
import {
  formatAPIPromptLanguageForForm,
  getPercentageFromWeight,
  getWeightFromPercentage,
} from "../../utils/utils";
import {
  DEFAULT_UPSCALE_FORM_VALUES,
  FORM_FIELDS_UPSCALE,
  FormTypeUpscale,
  MAX_UPSCALE_DIMENSION,
  MIN_UPSCALE_FACTOR,
} from "./constants";

export const getIsUpscaleTargetFileValid = (targetFile) =>
  targetFile &&
  targetFile.width * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION &&
  targetFile.height * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION;

export const getDefaultFormValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_UPSCALE_FORM_VALUES));

const formatFormValuesForCommonAPI = (
  inputForm: FormTypeUpscale,
): InferenceParameters => {
  const parameters = {
    ...DEFAULT_INFERENCE_FORM.parameters,
    generationType: "UPSCALE" as GenerationType,
    batchSize: 1,
    upscaleRatio: inputForm[FORM_FIELDS_UPSCALE.UPSCALE_FACTOR],
    prompt: inputForm[FORM_FIELDS_UPSCALE.PROMPT_TEXT],
    negativePrompt: inputForm[FORM_FIELDS_UPSCALE.NEGATIVE_PROMPT_TEXT],
    promptLanguage: inputForm[FORM_FIELDS_UPSCALE.PROMPT_LANGUAGE],
    seed: inputForm[FORM_FIELDS_UPSCALE.SEED] || -1,
    creativity: getWeightFromPercentage(
      inputForm[FORM_FIELDS_UPSCALE.CREATIVITY],
    ),
    files: [],
    styles: [],
  };

  if (parameters.creativity > 0) {
    parameters.resemblance = getWeightFromPercentage(
      inputForm[FORM_FIELDS_UPSCALE.RESEMBLANCE],
    );
    parameters.guidanceScale = inputForm[FORM_FIELDS_UPSCALE.GUIDANCE_SCALE];
    parameters.numInferenceSteps =
      inputForm[FORM_FIELDS_UPSCALE.DENOISING_STEPS];
  }

  return parameters;
};

export const formatFormValuesForInferenceAPI = (
  inputForm: FormTypeUpscale,
  refinementImages?: ReferenceImageType[],
): InferenceParameters => {
  const parameters = formatFormValuesForCommonAPI(inputForm);
  refinementImages?.forEach((image) => {
    parameters.files.push(formatReferenceImageForAPI(image));
  });
  return parameters;
};

export const formatFormValuesForSessionAPI = (
  inputForm: FormTypeUpscale,
): SessionStateInferenceParameters => {
  const parameters = formatFormValuesForCommonAPI(inputForm);
  return parameters;
};

export const formatInferenceParamsForForm = (
  params: SessionStateInferenceParameters,
): Partial<FormTypeUpscale> => {
  const outputForm: Partial<FormTypeUpscale> = {
    ...formatInferenceParamsForFormWithStyle(params),
  };

  if (params) {
    if (typeof params.upscaleRatio !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.UPSCALE_FACTOR] = params.upscaleRatio;
    }
    if (typeof params.prompt !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.PROMPT_TEXT] = params.prompt;
    }
    if (typeof params.negativePrompt !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.NEGATIVE_PROMPT_TEXT] =
        params.negativePrompt;
    }
    if (typeof params.promptLanguage !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.PROMPT_LANGUAGE] =
        formatAPIPromptLanguageForForm(params.promptLanguage);
    }
    if (typeof params.resemblance !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.RESEMBLANCE] = getPercentageFromWeight(
        params.resemblance,
      );
    }
    if (typeof params.creativity !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.CREATIVITY] = getPercentageFromWeight(
        params.creativity,
      );
    }
    if (typeof params.guidanceScale !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.GUIDANCE_SCALE] = params.guidanceScale;
    }
    if (typeof params.numInferenceSteps !== "undefined") {
      outputForm[FORM_FIELDS_UPSCALE.DENOISING_STEPS] =
        params.numInferenceSteps;
    }
  }
  return outputForm;
};
