import { DEFAULT_INFERENCE_FORM } from "../../../../features/forge/constants";
import {
  GenerationType,
  InferenceParameters,
  SessionStateInferenceParameters,
} from "../../../../types";
import { ReferenceImageType } from "../../fields/References/types";
import { formatReferenceImageForAPI } from "../../fields/References/utils";
import {
  DEFAULT_VECTORIZE_FORM_VALUES,
  FormTypeVectorize,
  MAX_VECTORIZE_DIMENSION,
} from "./constants";

export const getIsVectorizeTargetFileValid = (targetFile) =>
  targetFile &&
  targetFile.width <= MAX_VECTORIZE_DIMENSION &&
  targetFile.height <= MAX_VECTORIZE_DIMENSION;

export const getDefaultFormValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_VECTORIZE_FORM_VALUES));

const formatFormValuesForCommonAPI = (
  _: FormTypeVectorize,
): InferenceParameters => {
  const parameters = {
    ...DEFAULT_INFERENCE_FORM.parameters,
    generationType: "VECTORIZE_IMAGE" as GenerationType,
    batchSize: 1,
    files: [],
    styles: [],
  };

  return parameters;
};

export const formatFormValuesForInferenceAPI = (
  inputForm: FormTypeVectorize,
  refinementImages?: ReferenceImageType[],
): InferenceParameters => {
  const parameters = formatFormValuesForCommonAPI(inputForm);
  refinementImages?.forEach((image) => {
    parameters.files.push(formatReferenceImageForAPI(image));
  });
  return parameters;
};

export const formatFormValuesForSessionAPI = (
  inputForm: FormTypeVectorize,
): SessionStateInferenceParameters => {
  const parameters = formatFormValuesForCommonAPI(inputForm);
  return parameters;
};

export const formatInferenceParamsForForm = (
  _: SessionStateInferenceParameters,
): Partial<FormTypeVectorize> => {
  const outputForm: Partial<FormTypeVectorize> = {};

  return outputForm;
};
