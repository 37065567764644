import * as yup from "yup";

import { roundTo } from "../../../../utils/numbers";
import {
  FORM_FIELDS_UPSCALE_VIDEO,
  FormTypeUpscaleVideo,
  MAX_UPSCALE_DIMENSION,
  MAX_UPSCALE_FACTOR,
  MIN_UPSCALE_FACTOR,
} from "./constants";
import { getIsUpscaleTargetFileValid } from "./utils";

export const validationSchema = yup
  .object()
  .shape({
    [FORM_FIELDS_UPSCALE_VIDEO.UPSCALE_FACTOR]: yup
      .number()
      .typeError("Invalid value.")
      .required("Upscale factor is required")
      .min(MIN_UPSCALE_FACTOR, `Upscale factor at least ${MIN_UPSCALE_FACTOR}.`)
      .max(
        MAX_UPSCALE_FACTOR,
        `Scale factor must be at most ${MAX_UPSCALE_FACTOR}.`,
      )
      .when("$refinementVideos", (refinementVideos, schema) => {
        // Unwrap the nested array if needed
        const videos = Array.isArray(refinementVideos?.[0])
          ? refinementVideos[0]
          : refinementVideos;
        if (!videos?.length) return schema;
        const lowestMaxScaleFactor = videos.reduce((acc, video) => {
          if (!video?.width || !video?.height) return acc;

          const maxWidthScale = MAX_UPSCALE_DIMENSION / video.width;
          const maxHeightScale = MAX_UPSCALE_DIMENSION / video.height;

          const currentMaxScale = roundTo(
            Math.min(maxWidthScale, maxHeightScale, MAX_UPSCALE_FACTOR),
            1,
            "floor",
          );

          return Math.min(acc, currentMaxScale);
        }, MAX_UPSCALE_FACTOR);

        if (lowestMaxScaleFactor < MAX_UPSCALE_FACTOR) {
          const errorMessage =
            lowestMaxScaleFactor <= 1
              ? "Video is too large to upscale"
              : `Scale factor must be at most ${lowestMaxScaleFactor} to stay within ${MAX_UPSCALE_DIMENSION} pixels for all videos.`;
          return schema.max(lowestMaxScaleFactor, errorMessage);
        }

        return schema;
      }),
  })
  .test(
    "Target File Validation",
    "You must upload at least one video to upscale.",
    function () {
      const { refinementVideos } = this.options.context || {};

      if (!refinementVideos?.length) return false;
      return refinementVideos.every(
        (refinementVideo) =>
          refinementVideo?.width &&
          refinementVideo?.height &&
          getIsUpscaleTargetFileValid(refinementVideo),
      );
    },
  ) as yup.ObjectSchema<FormTypeUpscaleVideo>;
