import { GuidanceFileParameters } from "../../../../types";
import { InferenceStyleFragment } from "../../../inference/queries.graphql";
import { DEFAULT_REFERENCE_SIMILARITY } from "../../constants";
import { getWeightFromSimilarity } from "../../utils/utils";
import type { DimensionsType, PresetType } from "../AspectRatio/types";
import { getAreAspectRatiosEqual } from "../AspectRatio/utils";
import { REFERENCES_METADATA } from "./constants";
import { ReferenceImageType, ReferenceType, ReferenceVideoType } from "./types";

export const formatReferenceVideoForAPI = (
  reference: ReferenceVideoType,
): GuidanceFileParameters => ({
  type: "INIT_VIDEO",
  fileId: reference.driveFileId,
  url: reference.src,
  weight: 1,
});

export const formatReferenceForAPI = (
  reference: ReferenceType,
): GuidanceFileParameters => ({
  type: reference.type,
  fileId: reference.image.driveFileId,
  url: reference.image.src,
  preprocess: REFERENCES_METADATA[reference.type].preprocess,
  weight: getWeightFromSimilarity(reference.similarity),
});

export const formatReferenceImageForAPI = (
  image: ReferenceImageType,
): GuidanceFileParameters => ({
  type: "INIT",
  url: image.src,
  preprocess: REFERENCES_METADATA.INIT.preprocess,
  weight: 1,
});

export const formatReferenceImageForReferences = (
  image?: ReferenceImageType,
): ReferenceType[] =>
  image
    ? [{ type: "INIT", image, similarity: DEFAULT_REFERENCE_SIMILARITY }]
    : [];

export function getDefaultReference(
  params: Omit<ReferenceType, "similarity">,
): ReferenceType;

export function getDefaultReference(
  params: Omit<ReferenceType, "image" | "similarity">,
): ReferenceType;

export function getDefaultReference(params: ReferenceType): ReferenceType;

export function getDefaultReference(params: ReferenceType) {
  const {
    type,
    image = null,
    similarity = DEFAULT_REFERENCE_SIMILARITY,
  } = params;

  return { type, image, similarity };
} // Returns image dimensions adjusted to the style customSizeTarget.

export const getAdjustedReferenceImageDimensions = (
  style: InferenceStyleFragment,
  referenceImage: ReferenceImageType,
): DimensionsType => {
  const styleCustomSizeTargetWidth = style.capabilities.customSizeTarget;

  if (!styleCustomSizeTargetWidth) {
    return [referenceImage.width, referenceImage.height];
  }

  const newHeight =
    (styleCustomSizeTargetWidth * referenceImage.height) / referenceImage.width;

  const newHeightAdjusted = Math.floor(newHeight / 8) * 8; // Models like their values divisible by 8

  return [styleCustomSizeTargetWidth, newHeightAdjusted];
};

export const getReferencesWithUnsupportedAspectRatio = (
  references: ReferenceType[],
  stylePresets: PresetType[],
) =>
  references.filter((reference) => {
    return !stylePresets.some((stylePreset) =>
      getAreAspectRatiosEqual(stylePreset.dimensions, [
        reference.image.width,
        reference.image.height,
      ]),
    );
  });

// We only add the aspect ratio presets from references if the aspect ratio is not already included in style aspect ratios.

export const getAspectRatioPresetsFromReferences = (
  references: ReferenceType[],
  style: InferenceStyleFragment,
): PresetType[] =>
  references.map((reference) => {
    const adjustedImageDimensions = getAdjustedReferenceImageDimensions(
      style,
      reference.image,
    );

    return {
      label: REFERENCES_METADATA[reference.type].label,
      aspectRatioLabel: "Custom",
      aspectRatio: reference.image.width / reference.image.height,
      dimensions: adjustedImageDimensions,
    };
  });
